import { ViewStyle } from "react-native";
import { Orientation } from "expo-screen-orientation";
import { Platform } from "expo-modules-core";

export type DEVICE_ORIENTATION = "portrait" | "landscape";

export const landscapeStyle =
  (deviceOrientation: DEVICE_ORIENTATION) =>
  (landscapeStyle: ViewStyle): ViewStyle => {
    const isLandscape = deviceOrientation === "landscape";

    return isLandscape ? landscapeStyle : {};
  };

export const portraitStyle =
  (deviceOrientation: DEVICE_ORIENTATION) =>
  (portraitStyle: ViewStyle): ViewStyle => {
    const isPortrait = deviceOrientation === "portrait";

    return isPortrait ? portraitStyle : {};
  };

export const getOrientation = (
  orientation?: Orientation,
): DEVICE_ORIENTATION => {
  if (Platform.OS === "web") {
    return "portrait";
  }

  if (
    orientation &&
    [Orientation.LANDSCAPE_LEFT, Orientation.LANDSCAPE_RIGHT].includes(
      orientation,
    )
  ) {
    return "landscape";
  } else {
    return "portrait";
  }
};
