import React, { FC, useCallback } from "react";
import { ScrollView, View } from "react-native";
import { observer } from "mobx-react-lite";
import {
  CENTER_MIDDLE,
  CONTAINER,
  SMALL_BUTTON,
  SMALL_BUTTON_TEXT,
} from "../theme/view-style";
import { Button, Screen, Text, TextField } from "../components";
import { getOrientation } from "../utils/orientation-style";
import { useScreenOrientation } from "@use-expo/screen-orientation";
import { palette, spacing } from "../theme";
import { AppStackParamList, AppStackScreenProps } from "../navigators";
import { useStores } from "../models";
import * as Linking from "expo-linking";
import { useBrandTheme } from "../theme/use-brand-theme";
import { Race, RaceType } from "../models/race/race";
import { translate } from "swunitch-i18n";
import { RaceCalculator } from "../components/race-calculator/RaceCalculator";
import { useFocusEffect, useNavigation } from "@react-navigation/native";
import { StackNavigationProp } from "@react-navigation/stack";
import { Ionicons, MaterialCommunityIcons } from "@expo/vector-icons";
import Slider from "@react-native-community/slider";
import { useDebounceEffect } from "ahooks";
import { useConverterBottomSheet } from "../components/bottom-sheet/ConverterBottomSheetContext";

export const RacesScreen: FC<AppStackScreenProps<"races">> = observer(
  function RacesScreen({ route }) {
    const { globalStore } = useStores();
    const givenRaces: Array<Race> = route.params?.races || [];
    const search = globalStore.search;
    const [orientationInfo] = useScreenOrientation();
    const deviceOrientation = getOrientation(orientationInfo?.orientation);
    const theme = useBrandTheme();
    const navigation = useNavigation<StackNavigationProp<AppStackParamList>>();
    const races = givenRaces.length ? givenRaces : globalStore.searchedRaces;
    const { setOptions } = useConverterBottomSheet();

    useFocusEffect(
      useCallback(() => {
        setOptions({
          type: RaceType.Run,
          mode: "minimal",
        });
      }, []),
    );

    useDebounceEffect(
      () => {
        search.fetchLocations(
          search.searchedLocation || "",
          globalStore.latitude,
          globalStore.longitude,
        );
      },
      [search.searchedLocation],
      { wait: 500 },
    );

    useDebounceEffect(
      () => {
        if (givenRaces.length) {
          return;
        }

        globalStore.fetchSearch(search.searchDomain);
      },
      [
        search.title,
        search.distance,
        search.type,
        search.latitude,
        search.longitude,
      ],
      {
        wait: 500,
      },
    );

    return (
      <Screen preset="scroll">
        {givenRaces.length ? null : (
          <View
            style={{
              ...CONTAINER(deviceOrientation),
              marginTop: spacing.small,
            }}
          >
            <TextField
              value={search.title || undefined}
              onChangeText={(text) => search.updateSearch({ title: text })}
              placeholderTx="common.search"
              clearButtonMode="always"
            />

            <Text
              preset="formLabel"
              tx="racesScreen.addRace"
              onPress={() => Linking.openURL("https://pacevisor.com/new")}
              style={{ ...SMALL_BUTTON_TEXT, textAlign: "center" }}
            />

            <Text preset="subheading">{translate("sports.title")}</Text>
            <View
              style={{
                flexDirection: "row",
                gap: spacing.small,
                marginTop: spacing.tiny,
              }}
            >
              {Object.entries(RaceType).map(([key, value]) => (
                <Button
                  key={key}
                  preset={search.type === value ? "reversed" : "outlined"}
                  style={{ ...SMALL_BUTTON, borderWidth: 1 }}
                  textStyle={SMALL_BUTTON_TEXT}
                  tx={`sports.${value}`}
                  onPress={() => {
                    search.updateSearch(
                      value === search.type ? { type: null } : { type: value },
                    );
                  }}
                  RightAccessory={() =>
                    search.type === value ? (
                      <Ionicons
                        name="close-circle"
                        size={15}
                        color={palette.neutral100}
                        style={{ marginLeft: spacing.tiny }}
                      />
                    ) : null
                  }
                />
              ))}
            </View>

            <View style={{ marginTop: spacing.small }}>
              <TextField
                label={translate("racesScreen.location")}
                LabelTextProps={{ preset: "subheading" }}
                value={search.searchedLocation || undefined}
                onChangeText={(text) =>
                  search.updateSearch({
                    searchedLocation: text,
                    latitude: null,
                    longitude: null,
                  })
                }
                placeholderTx="racesScreen.location"
                clearButtonMode="always"
              />

              <ScrollView
                horizontal={true}
                style={{ marginTop: spacing.extraSmall }}
              >
                {search.searchedLocations.map((location) => {
                  const isSelected =
                    search.latitude === location.latitude &&
                    search.longitude === location.longitude;
                  return (
                    <Button
                      key={location.name}
                      preset={isSelected ? "reversed" : "outlined"}
                      style={{ ...SMALL_BUTTON, marginRight: spacing.small }}
                      textStyle={SMALL_BUTTON_TEXT}
                      text={location.name}
                      onPress={() =>
                        isSelected
                          ? search.updateSearch({
                              latitude: null,
                              longitude: null,
                            })
                          : search.updateSearch({
                              latitude: location.latitude,
                              longitude: location.longitude,
                            })
                      }
                      RightAccessory={() =>
                        isSelected ? (
                          <MaterialCommunityIcons
                            name="close-circle"
                            size={15}
                            color={palette.neutral100}
                            style={{ marginLeft: spacing.tiny }}
                          />
                        ) : null
                      }
                    />
                  );
                })}
              </ScrollView>
            </View>

            <View style={{ ...CENTER_MIDDLE, marginTop: spacing.medium }}>
              <Text preset="subheading">
                {translate("racesScreen.distance", {
                  count: search.distance || 0,
                })}
              </Text>

              {search.distance ? (
                <MaterialCommunityIcons
                  name="close-circle"
                  size={18}
                  color={theme.colors.text}
                  style={{ marginLeft: spacing.small }}
                  onPress={() => search.updateSearch({ distance: null })}
                />
              ) : null}
            </View>

            <Slider
              testID="slider"
              style={{ height: 30 }}
              minimumValue={0}
              maximumValue={200}
              tapToSeek={true}
              step={1}
              value={search.distance || undefined}
              onValueChange={(v) => search.updateSearch({ distance: v })}
              minimumTrackTintColor={palette.secondary400}
            />
          </View>
        )}

        {races.map((race) => (
          <RaceCalculator key={race.id} race={race} computeTime={false} />
        ))}
      </Screen>
    );
  },
);
