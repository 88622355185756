import React from "react";
import { TooltipProps } from "rn-tourguide";
import { View } from "react-native";
import { Text } from "./Text";
import { Button } from "./Button";
import { palette, spacing } from "../theme";
import { SMALL_BUTTON, SMALL_BUTTON_TEXT } from "../theme/view-style";

export function TourTooltip({
  isLastStep,
  handleNext,
  handleStop,
  currentStep,
  labels,
}: TooltipProps) {
  return (
    <View
      style={{
        borderRadius: 5,
        alignItems: "center",
        justifyContent: "center",
        width: "80%",
        backgroundColor: palette.neutral100 + "ef",
        padding: spacing.medium,
      }}
    >
      <View>
        <Text>{currentStep && currentStep.text}</Text>
      </View>
      <View style={{ marginTop: spacing.extraSmall }}>
        {!isLastStep ? (
          <Button
            style={SMALL_BUTTON}
            textStyle={SMALL_BUTTON_TEXT}
            preset="reversed"
            onPress={handleNext}
          >
            {labels?.next || "Next"}
          </Button>
        ) : (
          <Button
            style={SMALL_BUTTON}
            textStyle={SMALL_BUTTON_TEXT}
            preset="filled"
            onPress={handleStop}
          >
            {labels?.finish || "Finish"}
          </Button>
        )}
      </View>
    </View>
  );
}
