import { observer } from "mobx-react-lite";
import { ConverterProps } from "./ConverterProps";
import React, { useMemo } from "react";
import { SportTimeEdition } from "../race-calculator/SportTimeEdition";
import { translate } from "swunitch-i18n";
import { TourGuideZone } from "rn-tourguide";
import { TimeEdition } from "../race-calculator/TimeEdition";
import { BOTTOM_SHEET_TEXT } from "../../theme/view-style";
import { Text } from "../Text";

export const TimeComponent = observer(function TimeComponent(
  props: ConverterProps,
) {
  const { sport, onInEdition } = props;

  const time = useMemo(() => {
    return sport.getTimeForDistance();
  }, [
    sport.speed,
    sport.distance.value,
    sport.distance.distanceSize,
    sport.race?.id,
    sport.effort,
  ]);

  return (
    <>
      <TourGuideZone
        tourKey="intro"
        zone={5}
        text={translate("tourGuide.timeInput")}
      >
        <Text preset="subheading" style={BOTTOM_SHEET_TEXT}>
          {translate("units.time")}
        </Text>
        {sport.race ? (
          <TimeEdition
            time={time}
            onTimeChange={() => null}
            layout="big"
            disabled={true}
          />
        ) : (
          <SportTimeEdition
            distance={sport.distance.domainDistance}
            sport={sport}
            layout="big"
            onEdition={onInEdition}
          />
        )}
      </TourGuideZone>
    </>
  );
});
