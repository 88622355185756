import React, { FC } from "react";
import { observer } from "mobx-react-lite";
import { WebView } from "react-native-webview";
import { AppStackScreenProps } from "../navigators";

export const HelpScreen: FC<AppStackScreenProps<"help">> = observer(
  function HelpScreen({ route }) {
    return <WebView source={{ uri: route.params.url }} />;
  },
);
