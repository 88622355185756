import React, { useEffect, useState } from "react";
import { TouchableOpacity, View } from "react-native";
import { Text } from "./Text";
import { useBrandTheme } from "../theme/use-brand-theme";

interface TooltipProps {
  text: string;
  children: React.ReactNode;
  visible?: boolean;
}

export function Tooltip(props: TooltipProps) {
  const [visible, setVisible] = useState(false);
  const theme = useBrandTheme();

  useEffect(() => {
    if (!props.visible) {
      return;
    }

    const timer = setTimeout(() => {
      setVisible(true);
    }, 4000);
    const timer2 = setTimeout(() => {
      setVisible(false);
    }, 15000);

    return () => {
      clearTimeout(timer);
      clearTimeout(timer2);
    };
  }, []);

  const handlePress = () => {
    setVisible(false);
  };

  return (
    <View
      style={{
        position: "relative",
        alignItems: "center",
      }}
    >
      {props.children}
      {visible && (
        <TouchableOpacity
          style={{
            backgroundColor: theme.colors.backgroundApp,
            position: "absolute",
            bottom: "100%",
            marginBottom: 7,
            borderRadius: 5,
            alignItems: "center",
            minWidth: 130,
          }}
          onPress={handlePress}
        >
          <Text
            size="xxs"
            preset="bold"
            style={{ color: theme.colors.primary }}
          >
            {props.text}
          </Text>
          <View
            style={{
              position: "absolute",
              bottom: -5,
              left: 0,
              right: 0,
              marginLeft: "auto",
              marginRight: "auto",
              alignItems: "center",
            }}
          >
            <View
              style={{
                width: 0,
                height: 0,
                borderLeftWidth: 5,
                borderRightWidth: 5,
                borderTopWidth: 5,
                borderStyle: "solid",
                borderLeftColor: theme.colors.transparent,
                borderRightColor: theme.colors.transparent,
                borderTopColor: theme.colors.backgroundApp,
              }}
            />
          </View>
        </TouchableOpacity>
      )}
    </View>
  );
}
