import { Instance, types } from "mobx-state-tree";
import { AssertEqual, assertType } from "../services/are-types-equal";
import { SearchDomain } from "../domain/search";
import { isUndefined, omitBy } from "lodash";
import { LocationModel } from "./location";
import { api } from "../services/api";
import { LocationDomain } from "../domain/location";

export const SearchModel = types
  .model("Search")
  .props({
    title: types.maybeNull(types.string),
    type: types.maybeNull(types.string),
    distance: types.maybeNull(types.number),
    latitude: types.maybeNull(types.number),
    longitude: types.maybeNull(types.number),
    searchedLocation: types.maybeNull(types.string),
    searchedLocations: types.optional(types.array(LocationModel), []),
  })
  .views((self) => ({
    get searchDomain(): SearchDomain {
      return {
        title: self.title,
        type: self.type,
        distance: self.distance,
        latitude: self.latitude,
        longitude: self.longitude,
      };
    },
  }))
  .actions((self) => ({
    updateSearch(search: Partial<Search>) {
      const filtered = omitBy(search, isUndefined);
      Object.assign(self, filtered);
    },
    async fetchLocations(query: string, latitude?: number, longitude?: number) {
      const response = await api.getLocations(query, latitude, longitude);
      if (response.kind === "ok") {
        this._setSearchedLocations(response.locations);
      }
    },
    _setSearchedLocations(locations: LocationDomain[]) {
      self.searchedLocations.replace(locations);
    },
  }));

export interface Search extends Instance<typeof SearchModel> {}

assertType<AssertEqual<SearchDomain, Partial<Search>>>();
