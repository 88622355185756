import React, {
  createContext,
  useCallback,
  useContext,
  useRef,
  useState,
} from "react";
import BottomSheet, {
  BottomSheetBackdrop,
  BottomSheetBackgroundProps,
  BottomSheetFooter,
  BottomSheetFooterProps,
  BottomSheetHandle,
  BottomSheetProps,
  BottomSheetScrollView,
} from "@gorhom/bottom-sheet";
import { RaceType } from "../../models/race/race";
import { RunComponent } from "../../screens/run/run-component";
import { BikeComponent } from "../../screens/bike/bike-component";
import { SwimComponent } from "../../screens/swim/swim-component";
import { Button } from "../Button";
import { Ionicons } from "@expo/vector-icons";
import { useScreenOrientation } from "@use-expo/screen-orientation";
import { getOrientation } from "../../utils/orientation-style";
import { Platform } from "expo-modules-core";
import {
  ImageBackground,
  Keyboard,
  ScrollView,
  StyleSheet,
  TouchableOpacity,
  useWindowDimensions,
} from "react-native";
import { observer } from "mobx-react-lite";
import { CONTAINER } from "../../theme/view-style";
import { SportsTab } from "./SportsTab";
import { Timeline } from "../triathlon/Timeline";
import { TriathlonTabBar } from "../triathlon/TriathlonTabBar";
import { palette } from "../../theme";

interface Options {
  type: RaceType | "all";
  mode: "minimal" | "full";
  wait?: boolean;
}

const ConverterBottomSheetContext = createContext<{
  maximize: ({
    index,
    type,
  }: {
    index?: number;
    type?: RaceType | "all";
  }) => void;
  minimize: () => void;
  setOptions: (options: Options) => void;
  type: RaceType | "all";
}>({
  maximize: () => null,
  minimize: () => null,
  setOptions: () => ({
    type: RaceType.Run,
    mode: "full",
    wait: false,
  }),
  type: RaceType.Run,
});

export const ConverterBottomSheetProvider = observer(function ({
  children,
}: {
  children: React.ReactNode;
}) {
  const bottomSheetRef = useRef<BottomSheet>(null);
  const [type, setType] = useState<RaceType | "all">(RaceType.Run);
  const [bottomSheetProps, setBottomSheetProps] =
    useState<Partial<BottomSheetProps> | null>(null);
  const [bottomSheetIndex, setBottomSheetIndex] = useState(-1);
  const [orientationInfo] = useScreenOrientation();
  const deviceOrientation = getOrientation(orientationInfo?.orientation);
  const [hideSportSelection, setHideSportSelection] = useState(false);
  const [hideAll, setHideAll] = useState(false);
  const layout = useWindowDimensions();

  const BottomScrollViewForWeb =
    Platform.OS === "web" ? ScrollView : BottomSheetScrollView;

  function closeBottomSheet() {
    bottomSheetRef.current?.close();
    if (bottomSheetIndex === 1) {
      Keyboard.dismiss();
    }
  }

  const maximize = ({
    index,
    type,
  }: {
    index?: number;
    type?: RaceType | "all";
  }) => {
    if (bottomSheetRef && bottomSheetRef.current) {
      if (index === undefined) {
        bottomSheetRef.current.expand();
      } else {
        bottomSheetRef.current.snapToIndex(index);
      }

      if (type) {
        setType(type);
      }
    }
  };

  const setOptions = ({ type, mode, wait = false }: Options) => {
    const delay = wait ? 1000 : 0;
    setType(type);

    if (mode === "minimal") {
      setHideAll(true);
      setBottomSheetProps({});
      setHideSportSelection(true);
    } else {
      setHideAll(false);
      setBottomSheetProps({
        enablePanDownToClose: false,
        backdropComponent: null,
      });
      setHideSportSelection(false);
    }

    setTimeout(() => {
      if (mode === "minimal") {
        closeBottomSheet();
      } else {
        bottomSheetRef.current?.snapToIndex(0);
      }
    }, delay);
  };

  const footer = useCallback(
    (props: BottomSheetFooterProps) =>
      hideSportSelection ? null : (
        <BottomSheetFooter {...props}>
          <TriathlonTabBar />
        </BottomSheetFooter>
      ),

    [hideSportSelection],
  );

  const background = useCallback(
    (props: BottomSheetBackgroundProps) => (
      <ImageBackground
        source={require("../../../assets/images/blue-background.png")}
        {...props}
        style={{
          ...StyleSheet.absoluteFillObject,
          borderTopLeftRadius: 20,
          borderTopRightRadius: 20,
          overflow: "hidden",
        }}
      />
    ),
    [],
  );

  return (
    <ConverterBottomSheetContext.Provider
      value={{
        maximize,
        minimize: closeBottomSheet,
        setOptions,
        type,
      }}
    >
      {children}

      <BottomSheet
        ref={bottomSheetRef}
        index={bottomSheetIndex}
        onChange={setBottomSheetIndex}
        snapPoints={["17%", 480]}
        enablePanDownToClose={true}
        enableOverDrag={true}
        keyboardBlurBehavior="restore"
        backgroundComponent={background}
        onClose={closeBottomSheet}
        footerComponent={footer}
        handleIndicatorStyle={{
          backgroundColor: palette.neutral100,
        }}
        containerStyle={{
          marginHorizontal: layout.width > 500 ? (layout.width - 500) / 2 : 0,
        }}
        backdropComponent={(props) => <BottomSheetBackdrop {...props} />}
        handleComponent={(props) => (
          <TouchableOpacity onPress={() => maximize({})}>
            <BottomSheetHandle {...props} />
          </TouchableOpacity>
        )}
        {...bottomSheetProps}
      >
        <BottomScrollViewForWeb style={CONTAINER(deviceOrientation)}>
          {hideSportSelection ? null : (
            <SportsTab
              onTypeChange={(t) => {
                maximize({});
                setType(t);
              }}
              type={type}
              hideAll={hideAll}
            />
          )}
          {type === RaceType.Run && (
            <RunComponent hideSportSelection={hideSportSelection} />
          )}
          {type === RaceType.Bike && (
            <BikeComponent hideSportSelection={hideSportSelection} />
          )}
          {type === RaceType.Swim && (
            <SwimComponent hideSportSelection={hideSportSelection} />
          )}
          {type === "all" && <Timeline onChangeType={(t) => setType(t)} />}
        </BottomScrollViewForWeb>

        {(bottomSheetProps?.enablePanDownToClose === undefined ||
          !!bottomSheetProps?.enablePanDownToClose) && (
          <Button
            preset="light"
            onPress={closeBottomSheet}
            style={{
              position: "absolute",
              top: 0,
              right: 10,
              width: 40,
              height: 40,
              minHeight: 40,
              borderRadius: 50,
              paddingVertical: 0,
            }}
          >
            <Ionicons name="close" size={15} />
          </Button>
        )}
      </BottomSheet>
    </ConverterBottomSheetContext.Provider>
  );
});

export const useConverterBottomSheet = () => {
  return useContext(ConverterBottomSheetContext);
};
