import React from "react";
import { View } from "react-native";
import { observer } from "mobx-react-lite";
import { CONTAINER } from "../theme/view-style";
import { ListItem, Screen, Text, Toggle } from "../components";
import { useStores } from "../models";
import { getOrientation } from "../utils/orientation-style";
import { useScreenOrientation } from "@use-expo/screen-orientation";
import { translate } from "swunitch-i18n";

export const SettingsScreen = observer(function SettingsScreen() {
  const { globalStore } = useStores();
  const [orientationInfo] = useScreenOrientation();
  const deviceOrientation = getOrientation(orientationInfo?.orientation);

  return (
    <Screen preset="scroll">
      <View
        style={{
          ...CONTAINER(deviceOrientation),
        }}
      >
        <ListItem
          RightComponent={
            <Toggle
              value={globalStore.isMetricSystem}
              variant="switch"
              onPress={() => globalStore.switchSystem()}
              containerStyle={{ alignSelf: "center" }}
            />
          }
        >
          <Text preset="formLabel">
            {translate("settingsScreen.isMetricSystem")}
          </Text>
        </ListItem>
      </View>
    </Screen>
  );
});
