import { observer } from "mobx-react-lite";
import { ConverterProps } from "./ConverterProps";
import React from "react";
import { DistanceEdition } from "../race-calculator/DistanceEdition";
import { translate } from "swunitch-i18n";
import { TourGuideZone } from "rn-tourguide";
import { Distance } from "../../domain/race-calculator/Distance";

export const DistanceComponent = observer(function DistanceComponent(
  props: ConverterProps,
) {
  const { sport } = props;

  const handleDistanceChange = (distance: Distance) => {
    if (sport.distance) {
      sport.distance.updateValue(distance);
    }
  };

  return (
    <>
      <TourGuideZone
        tourKey="intro"
        zone={4}
        text={translate("tourGuide.distanceInput")}
      >
        {sport.race ? (
          <DistanceEdition
            distance={sport.raceDomainDistance}
            layout="big"
            onDistanceChange={() => null}
            disabled={true}
          />
        ) : (
          <DistanceEdition
            distance={sport.distance.domainDistance}
            layout="big"
            onDistanceChange={handleDistanceChange}
          />
        )}
      </TourGuideZone>
    </>
  );
});
