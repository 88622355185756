import { Segment } from "./GeoJsonSegmenter";
import { RaceType } from "../models/race/race";
import { BikeAdjuster } from "./BikeAdjuster";
import { RunAdjuster } from "./RunAdjuster";

export interface AdjusterConstructor {
  climberAbility: number;
  descenderAbility: number;
  baseSpeed: number;
  weight: number;
}

export class GeoJsonEnhancer {
  private segments: Segment[];
  private lapIndices: number[];
  private speedAdjuster: BikeAdjuster | RunAdjuster;

  constructor(
    segments: Segment[],
    lapIndices: number[],
    raceType: RaceType,
    data: AdjusterConstructor,
  ) {
    this.segments = segments;
    this.lapIndices = lapIndices.sort((a, b) => a - b);
    this.speedAdjuster =
      raceType === RaceType.Bike
        ? new BikeAdjuster(data)
        : new RunAdjuster(data);
  }

  public enhanceSegments(): EnhancedSegment[] {
    const timedSegment = this.addTime();
    return this.addCumulative(timedSegment);
  }

  private addTime(): TimedSegment[] {
    const enhancedSegments: TimedSegment[] = [];

    for (const segment of this.segments) {
      const speed = this.speedAdjuster.getSpeedAdjustment(segment);

      const timeInSeconds = (segment.length / speed) * 3600;

      enhancedSegments.push({
        ...segment,
        time: isFinite(timeInSeconds) ? timeInSeconds : 0,
        speed: isFinite(speed) ? speed : 0,
      });
    }

    return enhancedSegments;
  }

  private addCumulative(segments: TimedSegment[]): EnhancedSegment[] {
    let cumulativeTime = 0;
    let cumulativeElevationGain = 0;
    let cumulativeElevationLoss = 0;
    let segmentCount = 0;
    const lapTimeCounters: Record<number, number> = {};

    this.lapIndices.forEach((lapIndex) => {
      lapTimeCounters[lapIndex] = 0;
    });

    const enhancedSegments: EnhancedSegment[] = [];

    for (const segment of segments) {
      cumulativeTime += segment.time;
      cumulativeElevationGain += segment.elevationGain;
      cumulativeElevationLoss += segment.elevationLoss;
      segmentCount++;

      this.lapIndices.forEach((lapIndex) => {
        lapTimeCounters[lapIndex] += segment.time;
      });

      const lapTimes: Record<number, number> = {};
      this.lapIndices.forEach((lapIndex) => {
        if (segmentCount % lapIndex === 0) {
          lapTimes[lapIndex] = lapTimeCounters[lapIndex];
          lapTimeCounters[lapIndex] = 0;
        }
      });

      enhancedSegments.push({
        ...segment,
        cumulativeTime,
        cumulativeElevationGain,
        cumulativeElevationLoss,
        lapTimes: Object.keys(lapTimes).length > 0 ? lapTimes : null,
        coordinates: segment.coordinates,
      });
    }

    return enhancedSegments;
  }
}

interface TimedSegment extends Segment {
  time: number;
  speed: number;
}

export interface EnhancedSegment extends TimedSegment {
  cumulativeTime: number;
  cumulativeElevationGain: number;
  cumulativeElevationLoss: number;
  lapTimes: Record<number, number> | null;
}
