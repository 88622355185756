import { observer } from "mobx-react-lite";
import React, { useMemo, useState } from "react";
import { EffortComponent } from "../../components/bottom-sheet/EffortComponent";
import { useStores } from "../../models";
import { Sport } from "../../models/sport/sport";
import { RaceType } from "../../models/race/race";
import { MinPerHundredMeterComponent } from "../../components/bottom-sheet/MinPerHundredMeterComponent";
import { DistanceComponent } from "../../components/bottom-sheet/DistanceComponent";
import { TimeComponent } from "../../components/bottom-sheet/TimeComponent";
import { Platform, View } from "react-native";
import { spacing } from "../../theme";
import { BOTTOM_SHEET_TEXT, CENTER_MIDDLE } from "../../theme/view-style";
import { Text } from "../../components";
import { Ionicons } from "@expo/vector-icons";
import { useNavigation } from "@react-navigation/native";
import { StackNavigationProp } from "@react-navigation/stack";
import { AppStackParamList } from "../../navigators";

export const SwimComponent = observer(function SwimComponent({
  hideSportSelection,
}: {
  hideSportSelection: boolean;
}) {
  const { globalStore } = useStores();
  const sport: Sport = useMemo(
    () => globalStore.getSportByRaceType(RaceType.Swim),
    [],
  );
  const navigation = useNavigation<StackNavigationProp<AppStackParamList>>();
  const [isTimeInEdition, setTimeInEdition] = useState(false);

  return (
    <View style={{ gap: spacing.medium }}>
      {sport.race ? (
        <>
          <View
            style={[
              CENTER_MIDDLE,
              {
                marginBottom: -20,
              },
            ]}
          >
            <Text
              preset="subheading"
              style={BOTTOM_SHEET_TEXT}
              onPress={() =>
                navigation.navigate("race", {
                  race: Platform.OS === "web" ? sport.race!.id : sport.race!,
                })
              }
            >
              {sport.race.title}
            </Text>
            <Ionicons
              name="close-circle"
              size={20}
              onPress={() => sport.clearRaceDistance()}
              style={{ ...BOTTOM_SHEET_TEXT, marginLeft: spacing.small }}
            />
          </View>
          <Text
            tx="tourGuide.raceTimeSmall"
            size="xxs"
            style={{
              color: (BOTTOM_SHEET_TEXT.color as string) + "AA",
              marginBottom: -spacing.small,
            }}
          />
        </>
      ) : null}

      <MinPerHundredMeterComponent sport={sport} />

      {hideSportSelection ? null : (
        <>
          <View
            style={{
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            {isTimeInEdition ? null : <DistanceComponent sport={sport} />}
            <TimeComponent sport={sport} onInEdition={setTimeInEdition} />
          </View>
        </>
      )}

      <EffortComponent
        effort={sport.effort}
        onEffortChange={(e) => sport.updateEffort(e)}
      />
    </View>
  );
});
