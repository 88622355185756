import React, { FC } from "react";
import { observer } from "mobx-react-lite";
import { AppStackScreenProps } from "../navigators";
import { Button, Screen, Text, TextField } from "../components";
import * as DocumentPicker from "expo-document-picker";
import { GPXParser } from "../services/GPXParser";
import * as FileSystem from "expo-file-system";
import { CONTAINER } from "../theme/view-style";
import { View } from "react-native";
import { useScreenOrientation } from "@use-expo/screen-orientation";
import { getOrientation } from "../utils/orientation-style";
import { spacing } from "../theme";

export const EditRaceScreen: FC<AppStackScreenProps<"edit-race">> = observer(
  function EditRaceScreen({ route, navigation }) {
    const { race } = route.params;
    const [orientationInfo] = useScreenOrientation();
    const deviceOrientation = getOrientation(orientationInfo?.orientation);
    const [gpxFile, setGpxFile] = React.useState<string | null>(null);

    const handleGpxFilePick = async () => {
      const result = await DocumentPicker.getDocumentAsync({
        type: "application/octet-stream",
        multiple: false,
      });
      if (!result.canceled && result.assets.length) {
        const fileContent = await FileSystem.readAsStringAsync(
          result.assets[0].uri,
        );
        setGpxFile(result.assets[0].name);

        const json = GPXParser.convertGPXToGeoJSON(fileContent);
        race.updateLocal({ geoJson: JSON.stringify(json) });
      }
    };

    return (
      <Screen preset="scroll">
        <View style={CONTAINER(deviceOrientation)}>
          <TextField
            containerStyle={{ marginTop: spacing.medium }}
            value={race.title}
            onChangeText={(t) => race.updateLocal({ title: t })}
            labelTx="new.fields.title"
          />
          <TextField
            containerStyle={{ marginTop: spacing.medium }}
            value={race.distance === 0 ? "" : race.distance.toString()}
            keyboardType="numeric"
            onChangeText={(d) => race.updateLocal({ distance: Number(d) })}
            labelTx="new.fields.distance"
          />
          <Button
            style={{ marginTop: spacing.medium }}
            preset="filled"
            tx="raceScreen.gpxFile"
            onPress={handleGpxFilePick}
          />
          <Text>{gpxFile}</Text>

          <Button
            preset="reversed"
            tx="common.ok"
            onPress={() => {
              navigation.goBack();
            }}
          />
        </View>
      </Screen>
    );
  },
);
