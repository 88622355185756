import React, { FC, useEffect, useMemo } from "react";
import { observer } from "mobx-react-lite";
import { AppStackScreenProps } from "../navigators";
import { RaceModel, RaceType } from "../models/race/race";
import { RaceAnalysis } from "../components/race-analysis/RaceAnalysis";
import { useStores } from "../models";
import { Platform } from "react-native";
import { defaultRace } from "./default-race";
import { cloneDeep } from "lodash";

export const CustomEmbeddedRaceScreen: FC<
  AppStackScreenProps<"custom-embedded-race">
> = observer(function CustomEmbeddedRaceScreen({ route }) {
  const { baseSpeed, weight, ...race } = route.params || {};
  const { globalStore } = useStores();
  const storedRace = useMemo(
    () =>
      RaceModel.create({
        ...cloneDeep(defaultRace),
        ...race,
      }),
    [race],
  );
  const sport = useMemo(
    () => globalStore.getSportByRaceType(race.type as RaceType),
    [race.type],
  );

  useEffect(() => {
    if (baseSpeed) {
      sport.updateEffort(100);
      sport.updateSpeed(Number(baseSpeed));
    }

    if (weight) {
      sport.updateWeight(Number(weight));
    }
  }, []);

  return (
    <>
      <RaceAnalysis
        race={storedRace}
        headerShown={Platform.OS !== "web"}
        seeAllRoadbook={Platform.OS !== "web"}
      />
      {/* <WebDownloadButton */}
      {/*  onPress={() => { */}
      {/*    openUrl("https://pacevisor.com/download"); */}
      {/*    openUrl( */}
      {/*      `pacevisor://embed/races?${new URLSearchParams(route.params as unknown as Record<string, string>)}`, */}
      {/*    ); */}
      {/*  }} */}
      {/* /> */}
    </>
  );
});
