/**
 * The app navigator (formerly "AppNavigator" and "MainNavigator") is used for the primary
 * navigation flows of your app.
 * Generally speaking, it will contain an auth flow (registration, login, forgot password)
 * and a "main" flow which the user will use once logged in.
 */
import { NavigationContainer } from "@react-navigation/native";
import { StackScreenProps } from "@react-navigation/stack";
import { observer } from "mobx-react-lite";
import React, { useEffect } from "react";
import * as Linking from "expo-linking";
import { Platform, useColorScheme } from "react-native";
import Config from "../config";
import { RunScreen } from "../screens";
import { navigationRef, useBackButtonHandler } from "./navigationUtilities";
import { useStoreReviewIsAvailable } from "@use-expo/store-review";
import * as StoreReview from "expo-store-review";
import { translate } from "swunitch-i18n";
import { useStores } from "../models";
import { AppDarkTheme } from "../theme";
import { LinkingOptions } from "@react-navigation/native/lib/typescript/src/types";
import { createNativeStackNavigator } from "@react-navigation/native-stack";
import { SettingsScreen } from "../screens/settings-screen";
import { Race, RaceType } from "../models/race/race";
import { RaceScreen } from "../screens/race-screen";
import { RacesScreen } from "../screens/races-screen";
import { EmbeddedRaceScreen } from "../screens/embedded-race-screen";
import { AppLightTheme } from "../theme/colors-light";
import { CustomEmbeddedRaceScreen } from "../screens/custom-embedded-race-screen";
import { CustomEmbedRace } from "../domain/race";
import { MapScreen } from "../screens/map-screen";
import { DistancesScreen } from "../screens/distances-screen";
import { ConverterBottomSheetProvider } from "../components/bottom-sheet/ConverterBottomSheetContext";
import { ModalPortal } from "react-native-modals";
import { HelpScreen } from "../screens/help-screen";
import { EditRaceScreen } from "../screens/edit-race-screen";

/**
 * This type allows TypeScript to know what routes are defined in this navigator
 * as well as what properties (if any) they might take when navigating to them.
 *
 * If no params are allowed, pass through `undefined`. Generally speaking, we
 * recommend using your MobX-State-Tree store(s) to keep application state
 * rather than passing state through navigation params.
 *
 * For more information, see this documentation:
 *   https://reactnavigation.org/docs/params/
 *   https://reactnavigation.org/docs/typescript#type-checking-the-navigator
 *   https://reactnavigation.org/docs/typescript/#organizing-types
 */
export type AppStackParamList = {
  run: undefined;
  distances: { type: RaceType };
  settings: undefined;
  help: { url: string };
  map: { races: Array<Race> };
  race: { race: Race | string };
  "custom-embedded-race": CustomEmbedRace;
  "embedded-race": { race: string };
  "edit-race": { race: Race };
  races: { races?: Array<Race> } | undefined;
};

/**
 * This is a list of all the route names that will exit the app if the back button
 * is pressed while in that screen. Only affects Android.
 */
const exitRoutes = Config.exitRoutes;

export type AppStackScreenProps<T extends keyof AppStackParamList> =
  StackScreenProps<AppStackParamList, T>;

// Documentation: https://reactnavigation.org/docs/stack-navigator/
const Stack = createNativeStackNavigator<AppStackParamList>();
const AppStack = observer(function AppStack() {
  return (
    <Stack.Navigator initialRouteName="run">
      <Stack.Screen
        name="run"
        component={RunScreen}
        options={{
          headerShown: false,
          headerBackTitle: translate("sports.run"),
          title: translate("sports.run"),
        }}
      />
      <Stack.Screen
        name="distances"
        component={DistancesScreen}
        options={{
          headerShown: true,
          headerBackTitle: translate("common.back"),
          title: translate("distancesScreen.title"),
        }}
      />
      <Stack.Screen
        name="settings"
        component={SettingsScreen}
        options={{
          headerShown: true,
          headerBackTitle: translate("common.back"),
          title: translate("settingsScreen.title"),
        }}
      />
      <Stack.Screen
        name="help"
        component={HelpScreen}
        options={{
          headerShown: true,
          headerBackTitle: translate("common.back"),
          title: translate("ghost.title"),
        }}
      />
      <Stack.Screen
        name="map"
        component={MapScreen}
        options={{
          headerShown: true,
          headerBackTitle: translate("common.back"),
          title: translate("mapScreen.title"),
        }}
      />
      <Stack.Screen
        name="custom-embedded-race"
        component={CustomEmbeddedRaceScreen}
        options={{
          headerShown: false,
          headerBackTitle: translate("common.back"),
          title: translate("raceScreen.title"),
        }}
      />
      <Stack.Screen
        name="embedded-race"
        component={EmbeddedRaceScreen}
        options={{
          headerShown: false,
          headerBackTitle: translate("common.back"),
          title: translate("raceScreen.title"),
        }}
      />
      <Stack.Screen
        name="race"
        component={RaceScreen}
        options={{
          headerShown: true,
          headerBackTitle: translate("common.back"),
          title: translate("raceScreen.title"),
        }}
      />
      <Stack.Screen
        name="edit-race"
        component={EditRaceScreen}
        options={{
          headerShown: true,
          headerBackTitle: translate("common.back"),
          title: translate("editRaceScreen.title"),
        }}
      />
      <Stack.Screen
        name="races"
        component={RacesScreen}
        options={{
          headerShown: true,
          headerBackTitle: translate("common.back"),
          title: translate("racesScreen.title"),
        }}
      />
    </Stack.Navigator>
  );
});

interface NavigationProps
  extends Partial<React.ComponentProps<typeof NavigationContainer>> {}

const linking: LinkingOptions<AppStackParamList> = {
  prefixes: [Linking.createURL("/"), "https://pacevisor.com"],
  config: {
    initialRouteName: "run",
    screens: {
      run: "/",
      "custom-embedded-race": "/embed/races",
      "embedded-race": "/embed/races/:race",
      race: "/races/:race",
    },
  },
};

export const AppNavigator = observer(function AppNavigator(
  props: NavigationProps,
) {
  const colorScheme = useColorScheme();

  useBackButtonHandler((routeName) => exitRoutes.includes(routeName));

  const { globalStore } = useStores();
  const [isStoreReviewAvailable] = useStoreReviewIsAvailable();

  useEffect(() => {
    if (Platform.OS === "web") {
      document.body.style.overscrollBehaviorY = "auto";
    }
  }, []);

  useEffect(() => {
    globalStore.hasOpenedScreen();
    globalStore.fetchRaces();
  }, []);

  useEffect(() => {
    if (isStoreReviewAvailable && globalStore.isEnoughOpeningsForReview) {
      StoreReview.requestReview();
      globalStore.clearNumberOfOpenings();
    }
  }, [isStoreReviewAvailable, globalStore.isEnoughOpeningsForReview]);

  useEffect(() => {
    globalStore.runMigrations();
  }, []);

  return (
    <NavigationContainer
      ref={navigationRef}
      theme={
        colorScheme === "dark" && Platform.OS !== "web"
          ? AppDarkTheme
          : AppLightTheme
      }
      // @ts-ignore
      linking={linking}
      {...props}
    >
      <ConverterBottomSheetProvider>
        <AppStack />
      </ConverterBottomSheetProvider>
      <ModalPortal />
    </NavigationContainer>
  );
});
