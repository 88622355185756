import { Keyboard, View } from "react-native";
import React, { useState } from "react";
import Slider from "@react-native-community/slider";
import { palette, spacing } from "../../theme";
import { translate } from "swunitch-i18n";
import { Text } from "../Text";
import {
  BOTTOM_SHEET_TEXT,
  CENTER_MIDDLE,
  INPUT_SHADOW,
} from "../../theme/view-style";
import AntDesign from "@expo/vector-icons/AntDesign";
import { useBrandTheme } from "../../theme/use-brand-theme";
import * as Haptics from "expo-haptics";
import { TourGuideZone } from "rn-tourguide";

interface EffortProps {
  effort: number;
  onEffortChange: (effort: number) => void;
}

export function EffortComponent(props: EffortProps) {
  const { onEffortChange } = props;
  const [effort, setEffort] = useState(props.effort);
  const theme = useBrandTheme();

  function handleEffort(newEffort: number) {
    Keyboard.dismiss();
    Haptics.impactAsync(Haptics.ImpactFeedbackStyle.Light);

    setEffort(newEffort);
    onEffortChange(newEffort);
  }

  return (
    <View>
      <TourGuideZone
        tourKey="intro"
        zone={3}
        text={translate("tourGuide.effortCursor")}
      >
        <View style={CENTER_MIDDLE}>
          <Text preset="subheading" style={BOTTOM_SHEET_TEXT}>
            {effort}
            {translate("units.percent-pace")}
          </Text>
          <AntDesign
            name="reload1"
            size={20}
            color={palette.neutral100}
            style={{ marginLeft: spacing.medium }}
            onPress={() => handleEffort(100)}
          />
        </View>

        <Slider
          testID="slider"
          style={{ height: 30 }}
          minimumValue={50}
          maximumValue={150}
          tapToSeek={true}
          step={1}
          value={effort}
          onValueChange={(v) => handleEffort(v)}
          minimumTrackTintColor={theme.colors.primary}
          maximumTrackTintColor={INPUT_SHADOW.backgroundColor as string}
          thumbTintColor={theme.colors.primary}
        />
      </TourGuideZone>
    </View>
  );
}
