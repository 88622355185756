import {
  Platform,
  TextStyle,
  TouchableOpacity,
  View,
  ViewStyle,
} from "react-native";
import React, { useMemo } from "react";
import {
  CENTER_MIDDLE,
  CONTAINER,
  RACE_LINE_CONTAINER,
  RACE_SMALL_COLUMN,
  RACE_TITLE,
} from "../../theme/view-style";
import { Text } from "../Text";
import { AutoImage } from "../AutoImage";
import { useScreenOrientation } from "@use-expo/screen-orientation";
import { getOrientation } from "../../utils/orientation-style";
import { Race } from "../../models/race/race";
import { useNavigation } from "@react-navigation/native";
import { StackNavigationProp } from "@react-navigation/stack";
import { AppStackParamList } from "../../navigators";
import { Card } from "../Card";
import { observer } from "mobx-react-lite";
import { palette, spacing } from "../../theme";
import { LinearGradient } from "expo-linear-gradient";
import { getDay, getFullYear, getMonth } from "../../utils/formatDate";
import { Ionicons } from "@expo/vector-icons";
import { useBrandTheme } from "../../theme/use-brand-theme";

const FAVORITE_SPACING = 20;

interface DistanceThumbnailProps {
  race?: Race;
  distanceContent: React.ReactNode;
  style?: ViewStyle;
  content: React.ReactNode;
  floatingOptions?: React.ReactNode;
  isFavorite?: boolean;
  onClose?: () => void;
}

export const DistanceThumbnail = observer(function RaceThumbnail(
  props: DistanceThumbnailProps,
) {
  const {
    race,
    style,
    content,
    floatingOptions,
    isFavorite = false,
    onClose,
  } = props;

  const navigation = useNavigation<StackNavigationProp<AppStackParamList>>();
  const { title, imageUrl } = race || {};
  const [orientationInfo] = useScreenOrientation();
  const deviceOrientation = getOrientation(orientationInfo?.orientation);
  const theme = useBrandTheme();
  const date = useMemo(() => {
    if (!race || !race.date) {
      return null;
    }

    const month = getMonth(race.date);
    const monthShort = month.slice(0, month.length < 5 ? 4 : 3);
    const day = getDay(race.date);
    const year = getFullYear(race.date);

    return { month: monthShort, day, year };
  }, [race?.id]);

  const raceTextColorStyle: TextStyle = isFavorite
    ? { color: palette.neutral100 }
    : {};
  const raceBackgroundColorStyle: ViewStyle = isFavorite
    ? {
        marginTop: -FAVORITE_SPACING,
      }
    : {};

  return (
    <>
      <Card style={style}>
        {imageUrl ? (
          <TouchableOpacity
            onPress={() =>
              race &&
              navigation.navigate("race", {
                race: Platform.OS === "web" ? race.id : race,
              })
            }
          >
            <View
              style={[
                RACE_LINE_CONTAINER,
                { overflow: "hidden" },
                isFavorite && [
                  {
                    minHeight:
                      (RACE_LINE_CONTAINER.minHeight as number) +
                      FAVORITE_SPACING,
                  },
                ],
              ]}
            >
              <AutoImage
                source={{
                  uri: imageUrl,
                }}
                style={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  bottom: 0,
                  right: 0,
                  resizeMode: "cover",
                  width: "100%",
                  height: "100%",
                }}
              />
            </View>
          </TouchableOpacity>
        ) : null}
        <View
          style={{
            ...CONTAINER(deviceOrientation),
            ...raceBackgroundColorStyle,
          }}
        >
          {isFavorite ? (
            <LinearGradient
              pointerEvents="none"
              colors={[
                "transparent",
                palette.secondary500 + "AA",
                palette.secondary500,
                palette.secondary500,
              ]}
              style={{
                position: "absolute",
                left: 0,
                right: 0,
                bottom: 0,
                height: 130,
              }}
            />
          ) : null}
          <View
            style={[
              RACE_LINE_CONTAINER,
              title
                ? { marginVertical: spacing.small, minHeight: 0 }
                : { justifyContent: "center" },
            ]}
          >
            {title ? (
              <Text
                style={{ ...RACE_TITLE, ...raceTextColorStyle }}
                onPress={() =>
                  race &&
                  navigation.navigate("race", {
                    race: Platform.OS === "web" ? race.id : race,
                  })
                }
              >
                {title}
              </Text>
            ) : null}

            <View style={CENTER_MIDDLE}>
              <View style={RACE_SMALL_COLUMN}>{props.distanceContent}</View>

              {content}
            </View>
          </View>

          {floatingOptions}
        </View>

        {date ? (
          <View
            style={{
              borderRadius: 5,
              width: 75,
              margin: spacing.extraSmall,
              marginLeft: spacing.medium,
              ...CENTER_MIDDLE,
              justifyContent: "center",
              backgroundColor: palette.secondary500,
              position: "absolute",
            }}
          >
            <Text
              preset="bold"
              size="xxs"
              style={{
                textTransform: "capitalize",
                color: palette.neutral100,
              }}
            >
              {date.day} {date.month} {date.year}
            </Text>
          </View>
        ) : null}
      </Card>
      {onClose ? (
        <Ionicons
          name="close-circle"
          size={20}
          color={theme.colors.text}
          onPress={onClose}
          style={{
            position: "absolute",
            top: 0,
            right: 0,
            padding: spacing.extraSmall,
          }}
        />
      ) : null}
    </>
  );
});
