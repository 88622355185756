import { Platform } from "expo-modules-core";
import * as Linking from "expo-linking";

export function openUrl(url: string) {
  if (Platform.isDOMAvailable) {
    window.parent.location.href = url;
    return null;
  }

  return Linking.openURL(url);
}
