import React, { FC, useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { AppStackScreenProps } from "../navigators";
import { Race, RaceModel } from "../models/race/race";
import { RaceAnalysis } from "../components/race-analysis/RaceAnalysis";
import { api } from "../services/api";
import { defaultRace } from "./default-race";
import { recordEvent } from "../services/plausible";
import { Image, View } from "react-native";
import { Button } from "../components";
import { translate } from "swunitch-i18n";
import { openUrl } from "../services/openUrl";
import { SMALL_BUTTON, SMALL_BUTTON_TEXT } from "../theme/view-style";
import { spacing } from "../theme";

export const EmbeddedRaceScreen: FC<AppStackScreenProps<"embedded-race">> =
  observer(function EmbeddedRaceScreen({ route }) {
    const { race: raceCode } = route.params;
    const [race, setRace] = useState<Race>(defaultRace);

    useEffect(() => {
      if (!raceCode) return;

      api.getRace(raceCode).then((result) => {
        if (result.kind === "ok") {
          setRace(RaceModel.create(result.race));
        }
      });
    }, [raceCode]);

    useEffect(() => {
      if (race.id) {
        recordEvent(`embed/races/${race.id}`);
      }
    }, [race]);

    return (
      <>
        <RaceAnalysis race={race} headerShown={false} seeAllRoadbook={false} />
        <WebDownloadButton
          onPress={() => {
            recordEvent(`embed/races/${race.id}`, "open-in-app");
            openUrl(`pacevisor://races/${race.id}`);
          }}
        />
      </>
    );
  });

export function WebDownloadButton(props: { onPress: () => void }) {
  const onPress = props.onPress;

  return (
    <View
      style={{
        position: "fixed" as "absolute",
        bottom: 10,
        left: 0,
        right: 0,
        alignItems: "center",
      }}
    >
      <Button preset="reversed" onPress={onPress}>
        {translate("raceScreen.completeAnalysis")}
        <Image
          alt="Pacevisor"
          source={require("../../assets/images/logo-light-pacevisor.png")}
          style={{
            width: 80,
            height: 18,
            resizeMode: "contain",
            alignSelf: "center",
            marginBottom: -3,
          }}
        />
      </Button>
      <Button
        style={[
          SMALL_BUTTON,
          {
            marginTop: spacing.tiny,
            paddingVertical: 0,
          },
        ]}
        textStyle={[SMALL_BUTTON_TEXT, { textDecorationLine: "underline" }]}
        preset="default"
        onPress={() => openUrl("https://pacevisor.com/download")}
        tx="raceScreen.orDownload"
      />
    </View>
  );
}
