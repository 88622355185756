import { spacing } from "../theme";
import { Entypo } from "@expo/vector-icons";
import React from "react";
import { useTourGuideController } from "rn-tourguide";
import { useNavigation } from "@react-navigation/native";
import { StackNavigationProp } from "@react-navigation/stack";
import { AppStackParamList } from "../navigators";
import { View } from "react-native";
import { CONTAINER } from "../theme/view-style";
import { useScreenOrientation } from "@use-expo/screen-orientation";
import { getOrientation } from "../utils/orientation-style";
import { useScreen } from "./ScreenRefContext";
import { useBrandTheme } from "../theme/use-brand-theme";
import { useConverterBottomSheet } from "./bottom-sheet/ConverterBottomSheetContext";
import { RaceType } from "../models/race/race";

export function Help(props: { top?: number }) {
  const { top = 0 } = props;
  const navigation = useNavigation<StackNavigationProp<AppStackParamList>>();
  const { start } = useTourGuideController("intro");
  const [orientationInfo] = useScreenOrientation();
  const deviceOrientation = getOrientation(orientationInfo?.orientation);
  const { scrollToTop } = useScreen();
  const theme = useBrandTheme();
  const { maximize } = useConverterBottomSheet();

  function launchTour() {
    navigation.navigate("run");

    scrollToTop();
    maximize({
      type: RaceType.Run,
    });
    setTimeout(() => {
      start();
    }, 1000);
  }

  return (
    <View
      style={[
        CONTAINER(deviceOrientation),
        {
          position: "absolute",
          right: 0,
          top,
          zIndex: 10,
        },
      ]}
    >
      <Entypo
        name="help-with-circle"
        size={25}
        color={theme.colors.textDim}
        style={{ padding: spacing.micro, marginTop: spacing.micro }}
        onPress={launchTour}
      />
    </View>
  );
}
