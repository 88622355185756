import React, { FC, useCallback, useState } from "react";
import { ScrollView, View } from "react-native";
import { observer } from "mobx-react-lite";
import {
  BACKGROUND_CONTAINER,
  ESTIMATE_CONTAINER,
} from "../../theme/view-style";
import { useStores } from "../../models";
import { StackScreenProps } from "@react-navigation/stack";
import { AppStackParamList } from "../../navigators";
import { Screen, useSafeAreaInsetPadding } from "../../components";
import { useBrandTheme } from "../../theme/use-brand-theme";
import { spacing } from "../../theme";
import { AppModeSegmentedButtons } from "../../components/AppModeSegmentedButtons";
import { ListRaces } from "./ListRaces";
import { translate } from "swunitch-i18n";
import { useConverterBottomSheet } from "../../components/bottom-sheet/ConverterBottomSheetContext";
import { useFocusEffect } from "@react-navigation/native";
import { RaceType } from "../../models/race/race";
import { Help } from "../../components/Help";
import { NearByRaces } from "./NearByRaces";

export const RunScreen: FC<StackScreenProps<AppStackParamList, "run">> =
  observer(function RunScreen() {
    const insetPadding = useSafeAreaInsetPadding(["top"]);
    const { globalStore } = useStores();
    const theme = useBrandTheme();
    const { setOptions } = useConverterBottomSheet();
    const [isFirstOpen, setIsFirstOpen] = useState(true);

    useFocusEffect(
      useCallback(() => {
        setOptions({
          type: RaceType.Run,
          mode: "full",
          wait: isFirstOpen,
        });
        setIsFirstOpen(false);
      }, [isFirstOpen]),
    );

    return (
      <Screen preset="fixed">
        <View style={{ flexDirection: "column", height: "100%" }}>
          <View style={{ flex: 1 }}>
            <AppModeSegmentedButtons />
            <Help top={insetPadding.paddingTop as number} />
            <ScrollView
              style={{
                ...BACKGROUND_CONTAINER(theme),
                ...ESTIMATE_CONTAINER(theme),
                paddingTop: spacing.huge,
              }}
            >
              <View style={{ height: insetPadding.paddingTop }} />

              <ListRaces
                title={translate("runScreen.favorites")}
                races={globalStore.favoritedRaces}
                computeTime={true}
                carousel={true}
                isFavorites={true}
              />

              <NearByRaces />

              <ListRaces
                canShowTooltip={true}
                title={translate("runScreen.races")}
                races={
                  globalStore.searchedRaces.length
                    ? globalStore.searchedRaces
                    : globalStore.races
                }
                computeTime={false}
                isReminder={true}
                displayFilters={true}
              />

              <View style={{ height: 250 }} />
            </ScrollView>
          </View>
        </View>
      </Screen>
    );
  });
