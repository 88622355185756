import { applySnapshot, flow, Instance, types } from "mobx-state-tree";
import { DistanceSize } from "../../domain/race-calculator/DistanceSize";
import { Speed } from "../../domain/race-calculator/Speed";
import { getRootStore } from "../helpers/getRootStore";
import { DistanceModel } from "../distance/distance";
import { v4 as uuid } from "uuid";
import { Race, RaceDistanceModel } from "../race/race";
import { TimeConverter } from "../../domain/race-calculator/TimeConverter";
import { Time } from "../../domain/race-calculator/Time";
import { cloneDeep } from "lodash";
import { Distance } from "../../domain/race-calculator/Distance";

export const SportModel = types
  .model("Sport")
  .props({
    speed: types.number,
    effort: types.number,
    distance: DistanceModel,
    race: types.maybeNull(RaceDistanceModel),
    distanceSize: types.enumeration<DistanceSize>(Object.values(DistanceSize)),
    descenderAbility: types.optional(types.number, 1),
    climberAbility: types.optional(types.number, 1),
    distances: types.optional(types.array(DistanceModel), []),
    weight: types.optional(types.number, 75),
    transitionTime: types.optional(types.number, 60),
  })
  .views((self) => ({
    get domainSpeed(): Speed {
      const root = getRootStore(self);

      return Speed.of(
        self.speed,
        self.effort,
        self.distanceSize,
        root.globalStore.isMetricSystem,
      );
    },
    get raceDomainDistance(): Distance {
      const root = getRootStore(self);

      if (!self.race) {
        // NOTE: we are in a race distance, I should have tested it before use of the method
        return Distance.of(0, DistanceSize.long);
      }

      return getRaceDomainDistance(self.race, root.globalStore.isMetricSystem);
    },
  }))
  .actions((self) => ({
    getTimeForDistance: function (): Time {
      if (self.race) {
        const time = self.race.getTime(self as Sport);
        if (time) {
          return time;
        }
        return new Time();
      } else {
        const time = TimeConverter.toDomain(
          self.distance.domainDistance.getInSmallSize(),
          self.domainSpeed.getInAdaptedDistanceSize(),
        );
        return time;
      }
    },
    updateSpeed: function (speed: number) {
      self.speed = speed;
    },
    updateEffort: function (effort: number) {
      self.effort = effort;
    },
    updateClimberAbility: function (climberAbility: number) {
      self.climberAbility = climberAbility;
    },
    updateDescenderAbility: function (descenderAbility: number) {
      self.descenderAbility = descenderAbility;
    },
    updateWeight: function (weight: number) {
      if (weight > 30 && weight < 200) {
        self.weight = weight;
      }
    },
    updateRaceDistance: flow(function* (r: Race) {
      const race = RaceDistanceModel.create(cloneDeep(r));
      yield race.fetchGeoJson();
      self.race = race;
    }),
    clearRaceDistance: function () {
      self.race = null;
    },
    addDistances: function (id: string) {
      const index = self.distances.findIndex((d) => d.id === id);
      self.distances.splice(
        index + 1,
        0,
        DistanceModel.create({
          id: uuid(),
          value: 0,
          distanceSize: self.distanceSize,
        }),
      );
    },
    updateTransitionTime: function (transitionTime: number) {
      self.transitionTime = transitionTime;
    },
    removeDistances: function (id: string) {
      self.distances.replace(self.distances.filter((d) => d.id !== id));
    },
    _set: function (sport: Sport) {
      applySnapshot(self, sport);
    },
  }));

export function getRaceDomainDistance(
  race: Race,
  isMetricSystem: boolean,
): Distance {
  return race.type === "swim"
    ? Distance.of(race.distance, DistanceSize.short, isMetricSystem)
    : Distance.detect(race.distance, isMetricSystem);
}

export interface Sport extends Instance<typeof SportModel> {}
