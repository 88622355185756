import React, { useMemo } from "react";
import { View } from "react-native";
import { observer } from "mobx-react-lite";
import { palette, spacing } from "../../theme";
import { Race } from "../../models/race/race";
import { useBrandTheme } from "../../theme/use-brand-theme";
import { Feather, FontAwesome } from "@expo/vector-icons";
import { Text } from "../Text";
import { translate } from "swunitch-i18n";
import { CENTER_MIDDLE, CONTAINER } from "../../theme/view-style";
import { useScreenOrientation } from "@use-expo/screen-orientation";
import { getOrientation } from "../../utils/orientation-style";
import { useStores } from "../../models";
import { getDay, getHour, getMonth, getYear } from "../../utils/formatDate";
import { Platform } from "expo-modules-core";
import { recordEvent } from "../../services/plausible";
import * as Sharing from "expo-sharing";
import * as FileSystem from "expo-file-system";
import { openUrl } from "../../services/openUrl";
import { alertMessage } from "../../services/AlertMessage";
import { useNavigation } from "@react-navigation/native";
import { StackNavigationProp } from "@react-navigation/stack";
import { AppStackParamList } from "../../navigators";

const helpUrl = "https://pacevisor.com/ghost";

export const RaceTitle = observer(function RaceGraphs(props: {
  race: Race;
  ghost: string;
}) {
  const { race, ghost } = props;
  const theme = useBrandTheme();
  const { colors } = theme;
  const { globalStore } = useStores();
  const navigation = useNavigation<StackNavigationProp<AppStackParamList>>();
  const [orientationInfo] = useScreenOrientation();
  const deviceOrientation = getOrientation(orientationInfo?.orientation);
  const date = useMemo(() => {
    if (!race.date) {
      return null;
    }
    const month = getMonth(race.date);
    const monthShort = month.slice(0, month.length < 5 ? 4 : 3);
    const day = getDay(race.date);
    const year = getYear(race.date);

    return { month: monthShort, day, year };
  }, [race.id]);

  function openLink() {
    if (!race.url) return null;

    recordEvent(`races/${race.id}`, "open-website");

    return openUrl(race.url);
  }

  async function openGpx() {
    if (race.gpxUrl) {
      recordEvent(`races/${race.id}`, "open-gpx");

      if (Platform.OS === "web") {
        openUrl(race.gpxUrl);
      } else {
        const gpxName = race.gpxUrl.split("/").pop();
        const localUri = FileSystem.cacheDirectory + gpxName!;
        await FileSystem.downloadAsync(race.gpxUrl, localUri);
        await Sharing.shareAsync(localUri, { mimeType: "application/gpx+xml" });
      }
    }
  }

  async function downloadGhost() {
    if (!ghost) return;
    recordEvent(`races/${race.id}`, "open-ghost");
    await alertMessage("ghost.title", "ghost.description");

    const ghostName = `${race.id}-ghost.gpx`;
    if (Platform.OS === "web") {
      const ghostBlob = new Blob([ghost], { type: "application/gpx+xml" });
      const url = URL.createObjectURL(ghostBlob);
      const a = document.createElement("a");
      a.href = url;
      a.download = ghostName;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      URL.revokeObjectURL(url);

      openUrl(helpUrl);
    } else {
      navigation.navigate("help", { url: helpUrl });

      const localUri = FileSystem.cacheDirectory + ghostName;
      await FileSystem.writeAsStringAsync(localUri, ghost, {
        encoding: FileSystem.EncodingType.UTF8,
      });
      await Sharing.shareAsync(localUri, { mimeType: "application/gpx+xml" });
    }
  }

  return (
    <View style={CONTAINER(deviceOrientation)}>
      <View style={CENTER_MIDDLE}>
        <View
          style={{
            ...CENTER_MIDDLE,
            width: "90%",
          }}
        >
          <View
            style={{
              alignItems: "center",
              marginRight: spacing.extraSmall,
            }}
          >
            {date ? (
              <View
                style={{
                  borderRadius: 5,
                  overflow: "hidden",
                  width: 40,
                  borderColor: colors.primary,
                  borderWidth: 1,
                }}
              >
                <Text
                  preset="bold"
                  size="xxs"
                  style={{
                    fontSize: 10,
                    backgroundColor: colors.primary,
                    textTransform: "capitalize",
                    color: palette.neutral100,
                    textAlign: "center",
                    lineHeight: 12,
                  }}
                >
                  {date.month} {date.year}
                </Text>
                <Text
                  style={{
                    backgroundColor: palette.neutral100,
                    textAlign: "center",
                    color: palette.neutral900,
                  }}
                >
                  {date.day}
                </Text>
              </View>
            ) : null}

            <Text
              size="xxs"
              style={{
                color: colors.textDim,
              }}
            >
              {getHour(race.date)}
            </Text>
          </View>
          <View
            style={{
              flexShrink: 1,
            }}
          >
            <Text preset="heading">{race.title}</Text>
            <View style={{ flexDirection: "row", gap: spacing.small }}>
              {!!race.url && (
                <Text
                  size="xxs"
                  style={{
                    color: colors.textDim,
                  }}
                  onPress={openLink}
                >
                  {translate("raceScreen.seeWebsite")}{" "}
                  <Feather name="external-link" />
                </Text>
              )}
              {race.geoJson ? (
                <>
                  <Text
                    size="xxs"
                    style={{
                      color: colors.textDim,
                    }}
                    onPress={openGpx}
                  >
                    {translate("raceScreen.gpxFile")}{" "}
                    <Feather name="download" />
                  </Text>
                  <Text
                    size="xxs"
                    style={{
                      color: colors.textDim,
                    }}
                    onPress={downloadGhost}
                  >
                    Ghost <Feather name="download" />
                  </Text>
                </>
              ) : null}
            </View>
          </View>
        </View>

        <View
          style={{
            width: "10%",
            alignItems: "flex-end",
          }}
        >
          <FontAwesome
            name={globalStore.isFavoriteRace(race.id) ? "heart" : "heart-o"}
            size={25}
            color={palette.accent500}
            onPress={() => globalStore.toggleFavoriteRace(race.id)}
            style={{ padding: spacing.micro }}
          />
        </View>
      </View>
    </View>
  );
});
