import { palette, spacing } from "../../theme";
import { TouchableHighlight, View, ViewStyle } from "react-native";
import { Text } from "../Text";
import React from "react";
import { observer } from "mobx-react-lite";
import { useSafeAreaInsetPadding } from "../Screen";
import { TimeConverter } from "../../domain/race-calculator/TimeConverter";
import { CENTER_MIDDLE, PRIMARY_SEMI_BOLD_FONT } from "../../theme/view-style";
import MaterialCommunityIcons from "@expo/vector-icons/MaterialCommunityIcons";
import { useBrandTheme } from "../../theme/use-brand-theme";
import { useTriathlonHook } from "./TriathlonHook";
import { useConverterBottomSheet } from "../bottom-sheet/ConverterBottomSheetContext";

export const TriathlonTabBar = observer(function TriathlonTabBar() {
  const insetPadding = useSafeAreaInsetPadding(["bottom"]);
  const theme = useBrandTheme();
  const textStyle = { color: theme.colors.primary, ...PRIMARY_SEMI_BOLD_FONT };
  const { maximize } = useConverterBottomSheet();

  const {
    timeForRun,
    timeForBike,
    timeForBikeTransition,
    timeForSwim,
    timeForSwimTransition,
    fullTime,
  } = useTriathlonHook();

  return (
    <TouchableHighlight
      onPress={() => {
        maximize({ index: 1, type: "all" });
      }}
      style={{
        backgroundColor: palette.red,
        paddingTop: spacing.extraSmall,
        paddingBottom: insetPadding.paddingBottom || spacing.extraSmall,
      }}
    >
      <View>
        <View
          style={{
            ...CENTER_MIDDLE,
            justifyContent: "center",
          }}
        >
          <View style={{ flexDirection: "row", marginRight: spacing.small }}>
            <MaterialCommunityIcons
              name="swim"
              color={palette.neutral100}
              size={17}
            />
            <MaterialCommunityIcons
              name="run"
              color={palette.neutral100}
              size={17}
            />
            <MaterialCommunityIcons
              name="bike"
              color={palette.neutral100}
              size={17}
            />
          </View>
          <View>
            <Text style={{ ...textStyle, color: palette.neutral100 }}>
              {TimeConverter.toHuman(fullTime)}
            </Text>
          </View>
        </View>

        <View
          style={{
            justifyContent: "space-between",
            flexDirection: "row",
            backgroundColor: theme.colors.backgroundInput,
            marginHorizontal: spacing.small,
            borderRadius: 10,
          }}
        >
          <View style={centerTabBar}>
            <Text style={textStyle} size="xxs">
              <MaterialCommunityIcons
                name="swim"
                color={theme.colors.primary}
                size={15}
              />{" "}
              {TimeConverter.toHuman(timeForSwim, true)}
            </Text>
          </View>
          <View style={centerTransition}>
            <Text size="xxs" style={PRIMARY_SEMI_BOLD_FONT}>
              {TimeConverter.toHuman(timeForSwimTransition, true)}
            </Text>
          </View>
          <View style={centerTabBar}>
            <Text style={textStyle} size="xxs">
              <MaterialCommunityIcons
                name="bike"
                color={theme.colors.primary}
                size={15}
              />{" "}
              {TimeConverter.toHuman(timeForBike, true)}
            </Text>
          </View>
          <View style={centerTransition}>
            <Text size="xxs" style={PRIMARY_SEMI_BOLD_FONT}>
              {TimeConverter.toHuman(timeForBikeTransition, true)}
            </Text>
          </View>
          <View style={centerTabBar}>
            <Text style={textStyle} size="xxs">
              <MaterialCommunityIcons
                name="run"
                color={theme.colors.primary}
                size={15}
              />
              {TimeConverter.toHuman(timeForRun, true)}
            </Text>
          </View>
        </View>
      </View>
    </TouchableHighlight>
  );
});

const centerTabBar: ViewStyle = {
  alignItems: "center",
  flex: 1,
};

const centerTransition: ViewStyle = {
  alignItems: "center",
  flex: 0.5,
};
