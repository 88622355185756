import React from "react";
import { View } from "react-native";
import { RACE_COLUMN, RACE_OPTIONS } from "../../theme/view-style";
import { spacing } from "../../theme";
import MaterialCommunityIcons from "@expo/vector-icons/MaterialCommunityIcons";
import { SportTimeEdition } from "./SportTimeEdition";
import { DistanceEdition } from "./DistanceEdition";
import { useBrandTheme } from "../../theme/use-brand-theme";
import { Sport } from "../../models/sport/sport";
import { observer } from "mobx-react-lite";
import { DistanceModelInterface } from "../../models/distance/distance";
import { DistanceThumbnail } from "./DistanceThumbnail";
import { RaceCalculator } from "./RaceCalculator";
import { Distance } from "../../domain/race-calculator/Distance";

interface CustomDistanceCalculatorsProps {
  sport: Sport;
}

interface CustomDistanceCalculatorProps {
  sport: Sport;
  addCalculator?: () => void;
  removeCalculator?: () => void;
  canRemove: boolean;
  canAdd?: boolean;
  distance: DistanceModelInterface;
}

export const CustomDistanceCalculator = observer(
  function CustomDistanceCalculator(props: CustomDistanceCalculatorProps) {
    const {
      distance,
      sport,
      addCalculator = () => null,
      removeCalculator = () => null,
      canRemove,
      canAdd = true,
    } = props;
    const theme = useBrandTheme();

    const handleDistanceChange = (d: Distance) => {
      distance.updateValue(d);
    };

    return (
      <DistanceThumbnail
        distanceContent={
          <DistanceEdition
            distance={distance.domainDistance}
            onDistanceChange={handleDistanceChange}
          />
        }
        content={
          <>
            <View style={RACE_COLUMN}>
              <SportTimeEdition
                distance={distance.domainDistance}
                sport={sport}
              />
            </View>
            <View style={RACE_OPTIONS}>
              {canAdd ? (
                <MaterialCommunityIcons
                  name="plus-circle"
                  size={20}
                  color={theme.colors.border}
                  onPress={addCalculator}
                  style={{ padding: spacing.extraSmall, paddingRight: 0 }}
                />
              ) : null}
              {canRemove ? (
                <MaterialCommunityIcons
                  name="minus-circle"
                  size={20}
                  color={theme.colors.border}
                  onPress={removeCalculator}
                  style={{ padding: spacing.extraSmall }}
                />
              ) : null}
            </View>
          </>
        }
      />
    );
  },
);

export const CustomDistanceCalculators = observer(
  function CustomDistanceCalculators(props: CustomDistanceCalculatorsProps) {
    const { sport } = props;
    const canRemoveIfMoreThanOne = sport.distances.length !== 1;

    return (
      <View>
        {sport.race ? (
          <RaceCalculator race={sport.race} computeTime={true} />
        ) : (
          <CustomDistanceCalculator
            sport={sport}
            distance={sport.distance}
            canRemove={false}
            canAdd={false}
          />
        )}

        {sport.distances.map((distance) => (
          <CustomDistanceCalculator
            key={distance.id}
            distance={distance}
            sport={sport}
            canRemove={canRemoveIfMoreThanOne}
            addCalculator={() => sport.addDistances(distance.id)}
            removeCalculator={() => sport.removeDistances(distance.id)}
          />
        ))}
      </View>
    );
  },
);
