import React, { useMemo, useState } from "react";
import {
  TextStyle,
  TouchableOpacity,
  useWindowDimensions,
  View,
  ViewStyle,
} from "react-native";
import { observer } from "mobx-react-lite";
import { Button, Text } from "../index";
import { palette, spacing } from "../../theme";
import { EnhancedSegment } from "../../services/GeoJsonEnhancer";
import { DimensionValue } from "react-native/Libraries/StyleSheet/StyleSheetTypes";
import { translate } from "swunitch-i18n";
import { TimeConverter } from "../../domain/race-calculator/TimeConverter";
import { MinPerKilometer } from "../../domain/speed-converters/MinPerKilometer";
import { formatNumber } from "../../utils/formatNumber";
import { Speed } from "../../domain/race-calculator/Speed";
import { useBrandTheme } from "../../theme/use-brand-theme";
import { KilometerPerHour } from "../../domain/speed-converters/KilometerPerHour";
import { CARD_MARGIN, SMALL_BUTTON } from "../../theme/view-style";

interface RaceTableProps {
  segments: EnhancedSegment[];
  seeAll: boolean;
}

export const RaceTable = observer(function RaceTable(props: RaceTableProps) {
  const { segments, seeAll } = props;
  const theme = useBrandTheme();
  const { colors } = theme;
  const [selectedSegments, setSelectedSegments] = useState<Array<number>>([]);
  const [seeMore, setSeeMore] = useState<boolean>(seeAll);
  const layout = useWindowDimensions();

  const toggleSelectedSegment = (distance: number) => {
    if (selectedSegments.includes(distance)) {
      setSelectedSegments(selectedSegments.filter((s) => s !== distance));
    } else {
      setSelectedSegments([...selectedSegments, distance]);
    }
  };

  const tableElements = useMemo(
    () =>
      segments
        .filter((_, index) => {
          if (seeMore) {
            return true;
          }
          return index < 5;
        })
        .map((segment, index) => {
          const _5km: number | undefined = Object.values(
            segment.lapTimes || {},
          )[0];
          const _10km: number | undefined = Object.values(
            segment.lapTimes || {},
          )[1];

          return (
            <TouchableOpacity
              key={index}
              style={[
                tableRow,
                {
                  backgroundColor: selectedSegments.includes(segment.distance)
                    ? colors.primaryLight
                    : (index + 1) % 5 === 0
                      ? palette.neutral400 + "77"
                      : undefined,
                },
              ]}
              onPress={() => toggleSelectedSegment(segment.distance)}
            >
              <Text preset="bold" style={tableCell(0.8)}>
                {formatNumber(
                  KilometerPerHour.fromMeterPerHour(segment.distance),
                )}
              </Text>
              <Text style={tableCell(0.6)}>
                {formatNumber(segment.elevationGain)}
              </Text>
              <Text style={tableCell(0.6)}>
                {formatNumber(segment.elevationLoss)}
              </Text>
              <Text style={tableCell()}>
                {formatNumber(segment.cumulativeElevationGain)}
              </Text>
              <Text style={tableCell()}>
                {formatNumber(segment.cumulativeElevationLoss)}
              </Text>
              <Text style={tableCell()}>
                {MinPerKilometer.fromMeterPerHour(
                  Speed.of(segment.speed).getSpeedWithEffort(),
                )}
              </Text>
              <Text style={tableCell(2.5)}>
                {TimeConverter.toHuman(
                  TimeConverter.fromSecondsToDomain(segment.cumulativeTime),
                )}
              </Text>
              <Text style={tableCell()}>
                {_5km &&
                  TimeConverter.toHuman(
                    TimeConverter.fromSecondsToDomain(_5km),
                  )}
              </Text>
              <Text style={tableCell(1.5)}>
                {_10km &&
                  TimeConverter.toHuman(
                    TimeConverter.fromSecondsToDomain(_10km),
                  )}
              </Text>
            </TouchableOpacity>
          );
        }),
    [segments, selectedSegments, seeMore],
  );

  return (
    <>
      {tableElements}

      {seeMore ? null : (
        <View
          style={{
            width: layout.width - CARD_MARGIN,
            padding: spacing.extraSmall,
          }}
        >
          <Button
            preset="outlined"
            style={SMALL_BUTTON}
            onPress={() => setSeeMore(!seeMore)}
          >
            <Text>{translate("raceScreen.seeMore")}</Text>
          </Button>
        </View>
      )}
    </>
  );
});

export function RaceTableHeader() {
  return (
    <View style={raceTable}>
      <View style={{ ...tableRow, flexDirection: "row" }}>
        <Text preset="bold" style={tableHeader(0.8)}>
          Km
        </Text>
        <Text preset="bold" style={tableHeader(0.6)}>
          D+
        </Text>
        <Text preset="bold" style={tableHeader(0.6)}>
          D-
        </Text>
        <Text preset="bold" style={tableHeader()}>
          Cumul D+
        </Text>
        <Text preset="bold" style={tableHeader()}>
          Cumul D-
        </Text>
        <Text preset="bold" style={tableHeader()}>
          Tmp/km
        </Text>
        <Text preset="bold" style={tableHeader(2.5)}>
          Tmp cumul
        </Text>
        <Text preset="bold" style={tableHeader()}>
          5{translate("units.kilometer")}
        </Text>
        <Text preset="bold" style={tableHeader(1.5)}>
          10{translate("units.kilometer")}
        </Text>
      </View>
    </View>
  );
}

export const raceTable: ViewStyle = {
  minWidth: 800,
};

const tableRow: ViewStyle = {
  flexDirection: "row",
  borderBottomWidth: 1,
  borderBottomColor: "#ddd",
  padding: 5,
  width: "100%",
};

const tableHeader = (flex = 1): TextStyle => ({
  width: (flex * 10 + "%") as DimensionValue,
  textAlign: "center",
});

const tableCell = (flex = 1): TextStyle => ({
  width: (flex * 10 + "%") as DimensionValue,
  textAlign: "center",
});
