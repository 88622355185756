import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  Platform,
  ScrollView,
  TouchableOpacity,
  useWindowDimensions,
  View,
  ViewStyle,
} from "react-native";
import { observer } from "mobx-react-lite";
import { spacing } from "../../theme";
import { EnhancedSegment } from "../../services/GeoJsonEnhancer";
import { Race, RaceType } from "../../models/race/race";
import * as shape from "d3-shape";
import {
  Chart,
  HorizontalAxis,
  VerticalAxis,
} from "react-native-responsive-linechart";
import { KilometerPerHour } from "../../domain/speed-converters/KilometerPerHour";
import { MinPerKilometer } from "../../domain/speed-converters/MinPerKilometer";
import { formatNumber } from "../../utils/formatNumber";
import { Speed } from "../../domain/race-calculator/Speed";
import { useBrandTheme } from "../../theme/use-brand-theme";
import { LineChart } from "react-native-wagmi-charts";
import { Foundation, MaterialCommunityIcons } from "@expo/vector-icons";
import { Text } from "../Text";
import { TimeConverter } from "../../domain/race-calculator/TimeConverter";
import * as Haptics from "expo-haptics";
import { translate } from "swunitch-i18n";
import { CARD_MARGIN, CENTER_MIDDLE, CONTAINER } from "../../theme/view-style";
import { useScreenOrientation } from "@use-expo/screen-orientation";
import { getOrientation } from "../../utils/orientation-style";
import { Stop } from "react-native-svg";
import { getPaceUnit, getSpeedUnit } from "../../utils/humanUnit";
import { useStores } from "../../models";
import { defaultSegment } from "./defaultSegment";
import { AngleType } from "../../services/GeoJsonSegmenter";
import { Card } from "../Card";

interface RaceAnalysisProps {
  race: Race;
  profile: EnhancedSegment[];
  segments: EnhancedSegment[];
  onSegmentSelected: (segment: EnhancedSegment) => void;
}

export const RaceGraphs = observer(function RaceGraphs(
  props: RaceAnalysisProps,
) {
  const { profile, race, segments, onSegmentSelected } = props;
  const theme = useBrandTheme();
  const { colors } = theme;
  const layout = useWindowDimensions();
  const { globalStore } = useStores();
  const [orientationInfo] = useScreenOrientation();
  const deviceOrientation = getOrientation(orientationInfo?.orientation);
  const moduloIfManyPoints = profile.length > 100 ? 10 : 5;
  const [isFullscreen, setFullscreen] = useState(false);

  const fullscreenWidth = isFullscreen ? 1500 : 0;

  const graphHeight = 180;

  const chartPadding = {
    left: 40,
    bottom: 20,
    right: 20,
    top: 10,
  };

  const lineStyle: ViewStyle = {
    paddingLeft: chartPadding.left,
    marginTop: -5,
    position: "absolute",
  };

  const toggleFullscreen = useCallback(() => {
    setFullscreen(!isFullscreen);
  }, [isFullscreen]);

  const [selectedIndex, setSelectedIndex] = useState<{
    index: number;
    list: "profile" | "segments";
  }>({
    index: 0,
    list: "profile",
  });
  const lowestSpeed = useMemo(
    () => Math.max(...profile.map((segment) => segment.speed)),
    [profile],
  );
  const highestSpeed = useMemo(
    () => Math.min(...profile.map((segment) => segment.speed)),
    [profile],
  );

  function getSelectedSegment() {
    return (
      (selectedIndex.list === "profile"
        ? profile[selectedIndex.index]
        : segments[selectedIndex.index]) || defaultSegment
    );
  }

  const selectedSegment = useMemo(
    () => getSelectedSegment(),
    [selectedIndex, profile],
  );

  useEffect(() => {
    onSegmentSelected(getSelectedSegment());
  }, [selectedIndex]);

  const getSpeedForRaceType = useCallback(
    (speed: number): string => {
      if (race.type === RaceType.Bike) {
        return formatNumber(KilometerPerHour.fromMeterPerHour(speed));
      }

      return MinPerKilometer.fromMeterPerHour(speed);
    },
    [race.type],
  );

  const lineChart = useCallback(
    (graph: "profile" | "segments") => (
      <LineChart
        height={graphHeight}
        width={
          layout.width -
          chartPadding.left -
          chartPadding.right -
          CARD_MARGIN +
          fullscreenWidth
        }
        shape={shape.curveMonotoneX}
      >
        <LineChart.Path
          color={race.backgroundColor || colors.primaryDarker}
          pathProps={{
            width: 2,
          }}
        >
          {graph === "profile"
            ? profile.map((segment, index) => {
                if (segment.percentAngle >= AngleType.LOW) {
                  return (
                    <LineChart.Highlight
                      from={index}
                      to={index + 1}
                      key={index}
                      color={
                        segment.percentAngle >= AngleType.HIGH
                          ? "red"
                          : segment.percentAngle >= AngleType.MEDIUM
                            ? "#F98925"
                            : "#F6BF2A"
                      }
                      width={2}
                    />
                  );
                }
                return null;
              })
            : null}

          {graph === "profile"
            ? race.waypoints.map((w) => {
                const waypoint = profile.findIndex(
                  (segment) => segment.distance >= w.distance,
                );
                if (waypoint === -1) {
                  return null;
                }

                return (
                  <LineChart.Dot
                    at={waypoint}
                    key={w.distance}
                    color={colors.primary}
                  />
                );
              })
            : null}
          {graph === "profile" ? (
            <LineChart.Gradient color={race.backgroundColor || colors.primary}>
              <Stop
                offset="10%"
                stopColor={race.backgroundColor || colors.primary}
                stopOpacity={0.7}
              />
              <Stop
                offset="30%"
                stopColor={race.backgroundColor || colors.primary}
                stopOpacity={0.5}
              />
              <Stop
                offset="70%"
                stopColor={race.backgroundColor || colors.primary}
                stopOpacity={0.1}
              />
              <Stop
                offset="100%"
                stopColor={race.backgroundColor || colors.primary}
                stopOpacity={0}
              />
            </LineChart.Gradient>
          ) : null}
        </LineChart.Path>
        <LineChart.CursorLine snapToPoint={true} minDurationMs={150} />
        <LineChart.HoverTrap />
      </LineChart>
    ),
    [profile, race, layout.width, isFullscreen, theme.dark],
  );

  const profileChart = useMemo(() => {
    const preventStopScrollingForWeb = Platform.OS === "web";
    return (
      <ScrollView
        horizontal={true}
        scrollEnabled={preventStopScrollingForWeb || isFullscreen}
      >
        {/* @ts-ignore */}
        <Chart
          disableGestures={true}
          style={{
            height: graphHeight,
            width: layout.width - CARD_MARGIN + fullscreenWidth,
          }}
          data={profile.map((segment) => ({
            x: segment.distance,
            y: segment.altitude,
          }))}
          padding={chartPadding}
          yDomain={{
            min: Math.min(...profile.map((segment) => segment.altitude)),
            max: Math.max(...profile.map((segment) => segment.altitude)) + 10,
          }}
        >
          <VerticalAxis
            tickCount={6}
            theme={{
              labels: {
                formatter: (v) => v.toFixed(1),
                label: {
                  color: colors.text,
                },
              },
              grid: {
                stroke: {
                  color: colors.border,
                },
              },
            }}
          />
          <HorizontalAxis
            tickCount={moduloIfManyPoints}
            theme={{
              labels: {
                formatter: (v) =>
                  formatNumber(KilometerPerHour.fromMeterPerHour(v), 1),
                label: {
                  color: colors.text,
                },
              },
              grid: {
                stroke: {
                  color: colors.border,
                },
              },
            }}
          />
        </Chart>
        {profile.length ? (
          <View style={lineStyle}>
            <LineChart.Provider
              data={profile.map((segment, index) => ({
                timestamp: index,
                value: segment.altitude,
              }))}
              onCurrentIndexChange={(index) => {
                Haptics.impactAsync(Haptics.ImpactFeedbackStyle.Light);
                setSelectedIndex({
                  index,
                  list: "profile",
                });
              }}
              yRange={{
                min: Math.min(...profile.map((segment) => segment.altitude)),
                max:
                  Math.max(...profile.map((segment) => segment.altitude)) + 10,
              }}
            >
              {lineChart("profile")}
            </LineChart.Provider>
          </View>
        ) : null}
      </ScrollView>
    );
  }, [profile, layout.width, isFullscreen, theme.dark]);

  const speedChart = useMemo(() => {
    const preventStopScrollingForWeb = Platform.OS === "web";

    return (
      <ScrollView
        horizontal={true}
        scrollEnabled={preventStopScrollingForWeb || isFullscreen}
      >
        {/* @ts-ignore */}
        <Chart
          disableGestures={true}
          style={{
            height: graphHeight,
            width: layout.width - CARD_MARGIN + fullscreenWidth,
          }}
          data={segments.map((segment) => ({
            x: segment.distance,
            y: segment.speed,
          }))}
          padding={chartPadding}
          yDomain={{ min: highestSpeed - 100, max: lowestSpeed + 100 }}
        >
          <VerticalAxis
            tickCount={5}
            theme={{
              labels: {
                formatter: (v) =>
                  getSpeedForRaceType(Speed.of(v).getSpeedWithEffort()),
                label: {
                  color: colors.text,
                },
              },
              grid: {
                stroke: {
                  color: colors.border,
                },
              },
            }}
          />
          <HorizontalAxis
            tickCount={moduloIfManyPoints}
            theme={{
              labels: {
                formatter: (v) =>
                  formatNumber(KilometerPerHour.fromMeterPerHour(v), 1),
                label: {
                  color: colors.text,
                },
              },
              grid: {
                stroke: {
                  color: colors.border,
                },
              },
            }}
          />
        </Chart>
        {segments.length ? (
          <View style={lineStyle}>
            <LineChart.Provider
              data={segments.map((segment, index) => ({
                timestamp: index,
                value: segment.speed,
              }))}
              onCurrentIndexChange={(index) => {
                Haptics.impactAsync(Haptics.ImpactFeedbackStyle.Light);
                setSelectedIndex({
                  index,
                  list: "segments",
                });
              }}
              yRange={{ min: highestSpeed - 100, max: lowestSpeed + 100 }}
            >
              {lineChart("segments")}
            </LineChart.Provider>
          </View>
        ) : null}
      </ScrollView>
    );
  }, [
    segments,
    highestSpeed,
    lowestSpeed,
    layout.width,
    isFullscreen,
    theme.dark,
  ]);

  const metrics = useMemo(() => {
    return [
      [
        {
          icon: (
            <MaterialCommunityIcons
              name="clock-outline"
              size={15}
              color={race.backgroundColor || theme.colors.text}
            />
          ),
          text: TimeConverter.toHuman(
            TimeConverter.fromSecondsToDomain(selectedSegment.cumulativeTime),
          ),
        },
        {
          icon: (
            <MaterialCommunityIcons
              name="arrow-left-right"
              size={15}
              color={race.backgroundColor || theme.colors.text}
            />
          ),
          text: translate("raceScreen.totalDistance", {
            distance: formatNumber(
              KilometerPerHour.fromMeterPerHour(selectedSegment.distance),
            ),
          }),
        },
        race.type === RaceType.Bike
          ? {
              icon: (
                <MaterialCommunityIcons
                  name="speedometer"
                  size={15}
                  color={race.backgroundColor || theme.colors.text}
                />
              ),
              text: translate("raceScreen.pace", {
                value: formatNumber(
                  KilometerPerHour.fromMeterPerHour(selectedSegment.speed),
                ),
                unit: getSpeedUnit(globalStore.isMetricSystem),
              }),
            }
          : {
              icon: (
                <MaterialCommunityIcons
                  name="speedometer-medium"
                  size={15}
                  color={race.backgroundColor || theme.colors.text}
                />
              ),
              text: translate("raceScreen.pace", {
                value: MinPerKilometer.fromMeterPerHour(
                  Speed.of(selectedSegment.speed).getSpeedWithEffort(),
                ),
                unit: getPaceUnit(globalStore.isMetricSystem),
              }),
            },
      ],
      [
        {
          icon: (
            <Foundation
              name="mountains"
              size={15}
              color={race.backgroundColor || theme.colors.text}
            />
          ),
          text: translate("raceScreen.elevation", {
            value: formatNumber(selectedSegment.altitude),
          }),
        },
        {
          icon: (
            <MaterialCommunityIcons
              name="arrow-top-right"
              size={15}
              color={race.backgroundColor || theme.colors.text}
            />
          ),
          text: translate("raceScreen.elevation", {
            value: formatNumber(selectedSegment.elevationGain),
          }),
        },
        {
          text: formatNumber(selectedSegment.percentAngle) + "%",
          icon: (
            <MaterialCommunityIcons
              name="angle-acute"
              size={15}
              color={race.backgroundColor || theme.colors.text}
            />
          ),
        },
      ],
    ].map((list, index) => (
      <View
        key={index}
        style={{
          flexDirection: "row",
          gap: spacing.small,
          rowGap: 0,
          flexWrap: "wrap",
        }}
      >
        {list.map((item, index) => (
          <View
            style={{
              ...CENTER_MIDDLE,
              gap: spacing.micro,
            }}
            key={index}
          >
            {item.icon}
            <Text
              preset="bold"
              size="xxs"
              style={{
                color: race.backgroundColor || theme.colors.text,
              }}
            >
              {item.text}
            </Text>
          </View>
        ))}
      </View>
    ));
  }, [race, selectedSegment, globalStore.isMetricSystem, theme.dark]);

  return (
    <View>
      <Card padding={true}>
        <View style={CONTAINER(deviceOrientation)}>
          <View style={{ ...CENTER_MIDDLE, justifyContent: "space-between" }}>
            <Text preset="subheading">{translate("raceScreen.profile")}</Text>

            <TouchableOpacity
              onPress={() => {
                toggleFullscreen();
              }}
            >
              <MaterialCommunityIcons
                name={isFullscreen ? "fullscreen-exit" : "fullscreen"}
                size={25}
                color={theme.colors.text}
              />
            </TouchableOpacity>
          </View>
          <Text size="xxs" style={{ color: theme.colors.textDim }}>
            {translate("raceScreen.graphSubtitle")}
          </Text>
        </View>
        {profileChart}

        <View style={CONTAINER(deviceOrientation)}>{metrics}</View>
      </Card>

      <Card padding={true}>
        <View style={CONTAINER(deviceOrientation)}>
          <View style={{ ...CENTER_MIDDLE, justifyContent: "space-between" }}>
            <Text preset="subheading">{translate("raceScreen.paceTitle")}</Text>
            <TouchableOpacity
              onPress={() => {
                toggleFullscreen();
              }}
            >
              <MaterialCommunityIcons
                name={isFullscreen ? "fullscreen-exit" : "fullscreen"}
                size={25}
                color={theme.colors.text}
              />
            </TouchableOpacity>
          </View>
          <Text size="xxs" style={{ color: theme.colors.textDim }}>
            {translate("raceScreen.graphSubtitle")}
          </Text>
        </View>
        {speedChart}

        <View style={CONTAINER(deviceOrientation)}>{metrics}</View>
      </Card>
    </View>
  );
});
