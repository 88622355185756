import { View } from "react-native";
import React, { useMemo } from "react";
import {
  BOTTOM_SHEET_TEXT,
  BOTTOM_SHEET_UNIT_TEXT,
  INPUT_SHADOW,
  UNIT_DISPOSITION,
} from "../../theme/view-style";
import { translate } from "swunitch-i18n";
import { ConverterProps } from "./ConverterProps";
import { sanitizeKm } from "../../domain/race-calculator/sanitize-km";
import { Text } from "../Text";
import { useStores } from "../../models";
import { KilometerPerHour } from "../../domain/speed-converters/KilometerPerHour";
import { getSpeedUnit } from "../../utils/humanUnit";
import { formatNumber } from "../../utils/formatNumber";
import { updateSpeed } from "../../services/updateSpeed";
import { observer } from "mobx-react-lite";
import { TourGuideZone } from "rn-tourguide";
import { EditableConverter } from "./EditableConverter";

export const KilometerPerHourComponent = observer(
  function KilometerPerHourComponent(props: ConverterProps) {
    const { sport } = props;
    const { globalStore } = useStores();

    const speedKmh = useMemo(
      () =>
        KilometerPerHour.fromMeterPerHour(
          sport.domainSpeed.getSpeedWithEffort(),
        ),
      [sport.speed, sport.effort],
    );

    function handleKmh(s: number) {
      updateSpeed(sport, KilometerPerHour.toMeterPerHour(s));
    }

    return (
      <View>
        <Text preset="subheading" style={BOTTOM_SHEET_TEXT}>
          {translate("units.speed")}
        </Text>

        <View style={UNIT_DISPOSITION}>
          <TourGuideZone
            tourKey="intro"
            zone={0}
            text={translate("tourGuide.masInput")}
          >
            <EditableConverter
              mask={[/[\d.,]/, /[\d.,]/, /[\d.,]/, /[\d.,]/, /[\d.,]/]}
              onValueChange={(v) => handleKmh(sanitizeKm(v))}
              value={formatNumber(speedKmh)}
              testID="pace"
            />
          </TourGuideZone>

          <TourGuideZone
            tourKey="intro"
            zone={1}
            text={translate("tourGuide.unit")}
          >
            <View style={INPUT_SHADOW}>
              <Text
                style={BOTTOM_SHEET_UNIT_TEXT}
                onPress={() => globalStore.switchSystem()}
              >
                {getSpeedUnit(globalStore.isMetricSystem)}
              </Text>
            </View>
          </TourGuideZone>
        </View>
      </View>
    );
  },
);
