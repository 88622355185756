import React, { FC, useCallback, useMemo } from "react";
import { observer } from "mobx-react-lite";
import { CONTAINER } from "../theme/view-style";
import { Button, Screen } from "../components";
import { useStores } from "../models";
import { CustomDistanceCalculators } from "../components/race-calculator/CustomDistanceCalculator";
import { Sport } from "../models/sport/sport";
import { AppStackParamList, AppStackScreenProps } from "../navigators";
import { ImageBackground, View } from "react-native";
import { BikeComponent } from "./bike/bike-component";
import { useScreenOrientation } from "@use-expo/screen-orientation";
import { getOrientation } from "../utils/orientation-style";
import { RaceType } from "../models/race/race";
import { RunComponent } from "./run/run-component";
import { SwimComponent } from "./swim/swim-component";
import { useConverterBottomSheet } from "../components/bottom-sheet/ConverterBottomSheetContext";
import { SportsTab } from "../components/bottom-sheet/SportsTab";
import { useFocusEffect, useNavigation } from "@react-navigation/native";
import { StackNavigationProp } from "@react-navigation/stack";
import { spacing } from "../theme";
import { translate } from "swunitch-i18n";
import { ListRaces } from "./run/ListRaces";

export const DistancesScreen: FC<AppStackScreenProps<"distances">> = observer(
  function DistancesScreen({ route }) {
    const { type } = route.params;
    const { globalStore } = useStores();
    const { setOptions } = useConverterBottomSheet();
    const sport: Sport = useMemo(
      () => globalStore.getSportByRaceType(type),
      [type],
    );
    const [orientationInfo] = useScreenOrientation();
    const deviceOrientation = getOrientation(orientationInfo?.orientation);
    const navigation = useNavigation<StackNavigationProp<AppStackParamList>>();

    useFocusEffect(
      useCallback(() => {
        setOptions({
          type,
          mode: "minimal",
        });
      }, [type]),
    );

    function createLocalRace() {
      const race = globalStore.createLocalRace(type);
      navigation.navigate("edit-race", { race });
    }

    return (
      <Screen preset="scroll">
        <ImageBackground
          source={require("../../assets/images/blue-background.png")}
          style={{ paddingVertical: spacing.small }}
        >
          <View style={CONTAINER(deviceOrientation)}>
            <SportsTab
              onTypeChange={(t) => {
                if (t === "all") {
                  return;
                }
                navigation.navigate("distances", { type: t });
              }}
              type={type}
              hideAll={true}
            />

            {type === RaceType.Run && (
              <RunComponent hideSportSelection={true} />
            )}
            {type === RaceType.Bike && (
              <BikeComponent hideSportSelection={true} />
            )}
            {type === RaceType.Swim && (
              <SwimComponent hideSportSelection={true} />
            )}
          </View>
        </ImageBackground>

        <Button onPress={createLocalRace}>
          {translate("editRaceScreen.add")}
        </Button>

        <ListRaces
          title={translate("distancesScreen.local")}
          races={globalStore.localRaces.filter((r) => r.type === type)}
          computeTime={false}
          carousel={true}
          displayMap={false}
        />

        <CustomDistanceCalculators sport={sport} />
      </Screen>
    );
  },
);
