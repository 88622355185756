import React from "react";
import { View } from "react-native";
import { observer } from "mobx-react-lite";
import { CONTAINER, PRIMARY_SEMI_BOLD_FONT } from "../theme/view-style";
import { useScreenOrientation } from "@use-expo/screen-orientation";
import { getOrientation } from "../utils/orientation-style";
import SegmentedControl from "react-native-segmented-control-2";
import { palette, spacing } from "../theme";
import { useSafeAreaInsetPadding } from "./Screen";
import { useNavigation } from "@react-navigation/native";
import { StackNavigationProp } from "@react-navigation/stack";
import { AppStackParamList } from "../navigators";
import { RaceType } from "../models/race/race";
import { useBrandTheme } from "../theme/use-brand-theme";

export const AppModeSegmentedButtons = observer(
  function AppModeSegmentedButtons() {
    const insetPadding = useSafeAreaInsetPadding(["top"]);
    const [orientationInfo] = useScreenOrientation();
    const deviceOrientation = getOrientation(orientationInfo?.orientation);
    const navigation = useNavigation<StackNavigationProp<AppStackParamList>>();
    const theme = useBrandTheme();

    const selectMode = (index: number) => {
      if (index === 1) {
        navigation.navigate("distances", { type: RaceType.Run });
      }
    };

    return (
      <View
        style={[
          CONTAINER(deviceOrientation),
          {
            position: "absolute",
            zIndex: 1,
            marginBottom: spacing.medium,
            marginTop: insetPadding.paddingTop,
            width: "100%",
            alignItems: "center",
          },
        ]}
      >
        <SegmentedControl
          onChange={selectMode}
          value={0}
          textStyle={{ ...PRIMARY_SEMI_BOLD_FONT }}
          style={{
            borderRadius: 100,
            width: 250,
          }}
          tabStyle={{
            borderRadius: 100,
          }}
          selectedTabStyle={{
            borderRadius: 100,
          }}
          tabs={["Accueil", "Entraînement"]}
          activeTabColor={theme.colors.primary}
          activeTextColor={palette.neutral100}
        />
      </View>
    );
  },
);
