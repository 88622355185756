import { Instance, types } from "mobx-state-tree";
import { AssertEqual, assertType } from "../services/are-types-equal";
import { LocationDomain } from "../domain/location";

export const LocationModel = types.model("Location").props({
  name: types.string,
  latitude: types.number,
  longitude: types.number,
});

export interface Location extends Instance<typeof LocationModel> {}

assertType<AssertEqual<LocationDomain, Location>>();
