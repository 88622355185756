/**
 * This Api class lets you define an API endpoint and methods to request
 * data and process it.
 *
 * See the [Backend API Integration](https://github.com/infinitered/ignite/blob/master/docs/Backend-API-Integration.md)
 * documentation for more details.
 */
import { ApiResponse, ApisauceInstance, create } from "apisauce";
import Config from "../../config";
import {
  ApiConfig,
  GetGeoJsonResult,
  GetLocationsResult,
  GetRaceResult,
  GetRacesResult,
} from "./api.types";
import { getGeneralApiProblem } from "./apiProblem";
import { GPXParser } from "../GPXParser";
import { RaceAPI, RaceDTO } from "../../domain/race";
import { SearchDomain } from "../../domain/search";
import { LocationDomain } from "../../domain/location";

/**
 * Configuring the apisauce instance.
 */
export const DEFAULT_API_CONFIG: ApiConfig = {
  url: Config.API_URL,
  timeout: 10000,
};

/**
 * Manages all requests to the API. You can use this class to build out
 * various requests that you need to call from your backend API.
 */
export class Api {
  apisauce: ApisauceInstance;
  config: ApiConfig;

  /**
   * Set up our API instance. Keep this lightweight!
   */
  constructor(config: ApiConfig = DEFAULT_API_CONFIG) {
    this.config = config;
    this.apisauce = create({
      baseURL: this.config.url,
      timeout: this.config.timeout,
      headers: {
        Accept: "application/json",
      },
    });
  }

  async getRaces(search?: SearchDomain): Promise<GetRacesResult> {
    const response: ApiResponse<Array<RaceAPI>> = await this.apisauce.get(
      "/races",
      search,
    );

    if (!response.ok) {
      const problem = getGeneralApiProblem(response);
      if (problem) return problem;
    }

    return {
      kind: "ok",
      races: response.data!.map((r) => this.mapToRace(r)),
    };
  }

  async getRace(id: string): Promise<GetRaceResult> {
    const response: ApiResponse<RaceAPI> = await this.apisauce.get(
      `/races/${id}`,
    );

    if (!response.ok) {
      const problem = getGeneralApiProblem(response);
      if (problem) return problem;
    }

    return {
      kind: "ok",
      race: this.mapToRace(response.data!),
    };
  }

  async getLocations(
    query: string,
    latitude?: number,
    longitude?: number,
  ): Promise<GetLocationsResult> {
    const response: ApiResponse<Array<LocationDomain>> =
      await this.apisauce.get("/search/locations", {
        q: query,
        latitude,
        longitude,
      });

    if (!response.ok) {
      const problem = getGeneralApiProblem(response);
      if (problem) return problem;
    }

    return {
      kind: "ok",
      locations: response.data!,
    };
  }

  async getGeoJsonFromGpx(url: string): Promise<GetGeoJsonResult> {
    const response = await this.apisauce.get(url);

    if (!response.ok) {
      const problem = getGeneralApiProblem(response);
      if (problem) return problem;
    }

    const featureCollection = GPXParser.convertGPXToGeoJSON(
      response.data as string,
    );

    return {
      kind: "ok",
      data: JSON.stringify(featureCollection),
    };
  }

  private mapToRace(r: RaceAPI): RaceDTO {
    return {
      ...r,
      geoJson: null,
    };
  }
}

// Singleton instance of the API for convenience
export const api = new Api();
