import React, { useCallback, useEffect, useMemo, useState } from "react";
import { RefreshControl, ScrollView, View } from "react-native";
import { observer } from "mobx-react-lite";
import { CONTAINER } from "../../theme/view-style";
import { Button, Card, Screen, Text, TextField } from "../index";
import { spacing } from "../../theme";
import { AppStackParamList } from "../../navigators";
import { Platform } from "expo-modules-core";
import { Foundation, MaterialCommunityIcons } from "@expo/vector-icons";
import { useStores } from "../../models";
import { GeoJsonSegmenter } from "../../services/GeoJsonSegmenter";
import {
  EnhancedSegment,
  GeoJsonEnhancer,
} from "../../services/GeoJsonEnhancer";
import { GeoJsonMetadata, Metadata } from "../../services/GeoJsonMetadata";
import { Race, RaceType } from "../../models/race/race";
import { translate } from "swunitch-i18n";
import { useDebounceEffect } from "ahooks";
import { useScreenOrientation } from "@use-expo/screen-orientation";
import { getOrientation } from "../../utils/orientation-style";
import { TimeConverter } from "../../domain/race-calculator/TimeConverter";
import { useFocusEffect, useNavigation } from "@react-navigation/native";
import { KilometerPerHour } from "../../domain/speed-converters/KilometerPerHour";
import { MinPerKilometer } from "../../domain/speed-converters/MinPerKilometer";
import { getPaceUnit, getSpeedUnit } from "../../utils/humanUnit";
import { formatNumber } from "../../utils/formatNumber";
import { StackNavigationProp } from "@react-navigation/stack";
import { Sport } from "../../models/sport/sport";
import { useBrandTheme } from "../../theme/use-brand-theme";
import { AbilitySlider } from "./AbilitySlider";
import { Statistics } from "./Statistics";
import { Dashes } from "../Dashes";
import { RaceGraphs } from "./RaceGraphs";
import { RaceMap } from "./RaceMap";
import { defaultSegment } from "./defaultSegment";
import { RaceTitle } from "./RaceTitle";
import { useConverterBottomSheet } from "../bottom-sheet/ConverterBottomSheetContext";
import { GpxExporter } from "../../services/GpxExporter";
import { raceTable, RaceTable, RaceTableHeader } from "./RaceTable";

interface RaceAnalysisProps {
  race: Race;
  headerShown: boolean;
  seeAllRoadbook: boolean;
}

export const RaceAnalysis = observer(function RaceAnalysis(
  props: RaceAnalysisProps,
) {
  const { headerShown, race, seeAllRoadbook } = props;
  const theme = useBrandTheme();
  const { colors } = theme;
  const navigation = useNavigation<StackNavigationProp<AppStackParamList>>();
  const [selectedSegment, setSelectedSegment] =
    useState<EnhancedSegment>(defaultSegment);
  const { globalStore } = useStores();
  const { setOptions, maximize } = useConverterBottomSheet();
  const [orientationInfo] = useScreenOrientation();
  const deviceOrientation = getOrientation(orientationInfo?.orientation);
  const [segments, setSegments] = useState<EnhancedSegment[]>([]);
  const [ghost, setGhost] = useState<string>("");
  const [profile, setProfile] = useState<EnhancedSegment[]>([]);
  const [metadata, setMetadata] = useState<Metadata | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [moreOptions, setMoreOptions] = useState(false);
  const [canScroll, setCanScroll] = useState(true);
  const sport: Sport = useMemo(
    () => globalStore.getSportByRaceType(race.type as RaceType),
    [race.type],
  );

  useFocusEffect(
    useCallback(() => {
      setOptions({
        type: race.type as RaceType,
        mode: "minimal",
      });
    }, [race.type]),
  );

  useEffect(() => {
    navigation.setOptions({
      headerShown,
      title: race.title,
    });
  }, [headerShown, race]);

  function updateWeight(text: string) {
    const w = Number(text);
    sport.updateWeight(w);
  }

  useEffect(() => {
    if (race && typeof race.fetchGeoJson === "function") {
      race.fetchGeoJson();
    }
  }, [race]);

  useDebounceEffect(
    () => {
      if (race.geoJson && typeof race.fetchGeoJson === "function") {
        setIsLoading(true);
        const geoJson = race.geoJsonParsed!;
        const geoJsonSegmenter = new GeoJsonSegmenter(geoJson);

        const segments = geoJsonSegmenter.segmentTrail(1000);
        const profile = geoJsonSegmenter.segmentTrail(500);
        const ghost = geoJsonSegmenter.segmentTrail(50);

        const enhancedSegments = new GeoJsonEnhancer(
          segments,
          [5, 10],
          race.type as RaceType,
          {
            climberAbility: sport.climberAbility,
            descenderAbility: sport.descenderAbility,
            baseSpeed: sport.domainSpeed.getSpeedWithEffort(),
            weight: sport.weight,
          },
        ).enhanceSegments();
        const enhancedProfile = new GeoJsonEnhancer(
          profile,
          [5, 10],
          race.type as RaceType,
          {
            climberAbility: sport.climberAbility,
            descenderAbility: sport.descenderAbility,
            baseSpeed: sport.domainSpeed.getSpeedWithEffort(),
            weight: sport.weight,
          },
        ).enhanceSegments();
        const enhancedGhost = new GeoJsonEnhancer(
          ghost,
          [5, 10],
          race.type as RaceType,
          {
            climberAbility: sport.climberAbility,
            descenderAbility: sport.descenderAbility,
            baseSpeed: sport.domainSpeed.getSpeedWithEffort(),
            weight: sport.weight,
          },
        ).enhanceSegments();
        const metadata = new GeoJsonMetadata(geoJson).get();

        setGhost(GpxExporter.toGpx(race.title, enhancedGhost));
        setProfile(enhancedProfile);
        setSegments(enhancedSegments);
        setMetadata(metadata);
        setIsLoading(false);
      }
    },
    [
      race.geoJson,
      sport.speed,
      sport.effort,
      sport.descenderAbility,
      sport.climberAbility,
      sport.weight,
    ],
    {
      wait: 500,
    },
  );

  return (
    <Screen
      preset="scroll"
      backgroundColor={Platform.OS === "web" ? colors.transparent : undefined}
      ScrollViewProps={{
        scrollEnabled: canScroll,
        refreshControl: (
          <RefreshControl refreshing={isLoading} onRefresh={() => null} />
        ),
      }}
    >
      <Card padding={true}>
        <RaceTitle race={race} ghost={ghost} />
        <Statistics
          statistics={[
            {
              value: TimeConverter.toHuman(
                TimeConverter.fromSecondsToDomain(
                  segments[segments.length - 1]?.cumulativeTime || 0,
                ),
              ),
              icon: (
                <MaterialCommunityIcons
                  name="clock-outline"
                  size={20}
                  color={theme.colors.text}
                />
              ),
            },
            {
              value: translate("raceScreen.totalDistance", {
                distance: formatNumber(metadata?.totalLength || 0),
              }),
              icon: (
                <MaterialCommunityIcons
                  name="arrow-left-right"
                  size={20}
                  color={theme.colors.text}
                />
              ),
            },

            {
              value: translate("raceScreen.pace", {
                value: MinPerKilometer.fromMeterPerHour(
                  sport.domainSpeed.getSpeedWithEffort(),
                ),
                unit: getPaceUnit(globalStore.isMetricSystem),
              }),
              icon: (
                <MaterialCommunityIcons
                  name="speedometer-medium"
                  size={20}
                  color={theme.colors.text}
                />
              ),
            },
            {
              value: translate("raceScreen.pace", {
                value: formatNumber(
                  KilometerPerHour.fromMeterPerHour(
                    sport.domainSpeed.getSpeedWithEffort(),
                  ),
                ),
                unit: getSpeedUnit(globalStore.isMetricSystem),
              }),
              icon: (
                <MaterialCommunityIcons
                  name="speedometer"
                  size={20}
                  color={theme.colors.text}
                />
              ),
            },
            {
              value: translate("raceScreen.elevation", {
                value: formatNumber(
                  segments[segments.length - 1]?.cumulativeElevationGain || 0,
                ),
              }),
              icon: (
                <MaterialCommunityIcons
                  name="arrow-top-right"
                  size={20}
                  color={theme.colors.text}
                />
              ),
            },
            {
              value: translate("raceScreen.elevation", {
                value: formatNumber(
                  segments[segments.length - 1]?.cumulativeElevationLoss || 0,
                ),
              }),
              icon: (
                <MaterialCommunityIcons
                  name="arrow-bottom-right"
                  size={20}
                  color={theme.colors.text}
                />
              ),
            },
            {
              value: `${translate("raceScreen.elevation", {
                value: formatNumber(metadata?.highestPoint || 0),
              })} / ${translate("raceScreen.elevation", {
                value: formatNumber(metadata?.lowestPoint || 0),
              })}`,
              icon: (
                <View style={{ flexDirection: "row" }}>
                  <Foundation
                    name="mountains"
                    size={20}
                    color={theme.colors.text}
                  />
                  <View style={{ marginLeft: 2 }}>
                    <Text
                      style={{
                        color: theme.colors.text,
                        lineHeight: 10,
                        fontSize: 10,
                      }}
                    >
                      max
                    </Text>
                    <Text
                      style={{
                        color: theme.colors.text,
                        lineHeight: 10,
                        fontSize: 10,
                      }}
                    >
                      min
                    </Text>
                  </View>
                </View>
              ),
            },
            {
              value: translate("raceScreen.elevation", {
                value: formatNumber(metadata?.averageAltitude || 0),
              }),
              icon: (
                <View style={{ flexDirection: "row" }}>
                  <Foundation
                    name="mountains"
                    size={20}
                    color={theme.colors.text}
                  />
                  <Text size="xxs" style={{ color: theme.colors.text }}>
                    {translate("raceScreen.elevationAvg")}
                  </Text>
                </View>
              ),
            },
          ]}
        />

        <View style={CONTAINER(deviceOrientation)}>
          <Button
            onPress={() =>
              maximize({
                index: 1,
              })
            }
            style={{
              backgroundColor: race.backgroundColor || colors.primary,
            }}
            textStyle={{
              color: race.textColor || colors.text,
            }}
          >
            {translate("raceScreen.editMyInfo")}
          </Button>

          <View style={{ marginTop: spacing.extraSmall }}>
            {moreOptions ? (
              <>
                {race.type === RaceType.Bike && (
                  <TextField
                    LabelTextProps={{
                      size: "xs",
                    }}
                    containerStyle={{
                      marginBottom: spacing.extraSmall,
                    }}
                    label={translate("raceScreen.weight")}
                    onChangeText={updateWeight}
                    defaultValue={sport.weight.toString()}
                    keyboardType="numeric"
                  />
                )}
                <AbilitySlider
                  onValueChange={(v) => sport.updateClimberAbility(v)}
                  value={sport.climberAbility}
                  race={race}
                  tx="raceScreen.climberNote"
                />

                <AbilitySlider
                  onValueChange={(v) => sport.updateDescenderAbility(v)}
                  value={sport.descenderAbility}
                  race={race}
                  tx="raceScreen.descenderNote"
                />
              </>
            ) : (
              <View style={{ alignSelf: "center" }}>
                <Text
                  size="xs"
                  onPress={() => setMoreOptions(true)}
                  preset="bold"
                  style={{
                    color: race.backgroundColor || colors.primary,
                  }}
                >
                  {translate("raceScreen.moreOptions")}
                </Text>
                <Dashes borderColor={race.backgroundColor || colors.primary} />
              </View>
            )}
          </View>
        </View>
      </Card>

      <Card>
        <RaceMap
          race={race}
          selectedSegment={selectedSegment}
          metadata={metadata}
          onMoveStart={() => setCanScroll(false)}
          onMoveEnd={() => setCanScroll(true)}
        />
      </Card>

      <RaceGraphs
        race={race}
        profile={profile}
        segments={segments}
        onSegmentSelected={(segment) => setSelectedSegment(segment)}
      />

      <Card
        padding={true}
        isLast={true}
        style={{ marginBottom: spacing.medium }}
      >
        <View style={CONTAINER(deviceOrientation)}>
          <Text preset="subheading">Roadbook</Text>
        </View>

        <ScrollView
          style={{
            flexDirection: Platform.OS === "web" ? "column" : "row",
          }}
          horizontal={true}
          nestedScrollEnabled={true}
          bounces={false}
        >
          <View style={{ ...raceTable, flex: 1 }}>
            <RaceTableHeader />
            <RaceTable segments={segments} seeAll={seeAllRoadbook} />
          </View>
        </ScrollView>
      </Card>
    </Screen>
  );
});
