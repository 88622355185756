import { View } from "react-native";
import MaterialCommunityIcons from "@expo/vector-icons/MaterialCommunityIcons";
import React from "react";
import { CENTER_MIDDLE } from "../../theme/view-style";

export function TriathlonIcon(props: { color: string }) {
  return (
    <View style={CENTER_MIDDLE}>
      <MaterialCommunityIcons name="swim" size={18} color={props.color} />
      <MaterialCommunityIcons name="run" size={18} color={props.color} />
      <MaterialCommunityIcons name="bike" size={18} color={props.color} />
    </View>
  );
}
