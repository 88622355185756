import { observer } from "mobx-react-lite";
import { Race } from "../../models/race/race";
import { ScrollView, useWindowDimensions, View } from "react-native";
import {
  CENTER_MIDDLE,
  CONTAINER,
  SMALL_BUTTON,
  SMALL_BUTTON_TEXT,
} from "../../theme/view-style";
import { Button, Text } from "../../components";
import { RaceCalculator } from "../../components/race-calculator/RaceCalculator";
import React from "react";
import { useScreenOrientation } from "@use-expo/screen-orientation";
import { getOrientation } from "../../utils/orientation-style";
import { spacing } from "../../theme";
import { Feather, MaterialCommunityIcons } from "@expo/vector-icons";
import { useBrandTheme } from "../../theme/use-brand-theme";
import { useNavigation } from "@react-navigation/native";
import { StackNavigationProp } from "@react-navigation/stack";
import { AppStackParamList } from "../../navigators";
import { ReminderRace } from "./ReminderRace";
import { translate } from "swunitch-i18n";
import { isEmpty, omitBy } from "lodash";
import { useStores } from "../../models";
import { isSearchEmpty } from "../../services/isSearchEmpty";
import { alertMessage } from "../../services/AlertMessage";

export const ListRaces = observer(function ListRaces(props: {
  title: string;
  races: Race[];
  computeTime: boolean;
  displayFilters?: boolean;
  isReminder?: boolean;
  carousel?: boolean;
  canShowTooltip?: boolean;
  isFavorites?: boolean;
  displayMap?: boolean;
}) {
  const {
    races,
    title,
    computeTime,
    displayFilters = false,
    isReminder = false,
    carousel = false,
    displayMap = true,
    canShowTooltip,
    isFavorites,
  } = props;
  const [orientationInfo] = useScreenOrientation();
  const deviceOrientation = getOrientation(orientationInfo?.orientation);
  const theme = useBrandTheme();
  const navigation = useNavigation<StackNavigationProp<AppStackParamList>>();
  const layout = useWindowDimensions();
  const { globalStore } = useStores();
  const hasFiltersEmpty =
    displayFilters &&
    isEmpty(omitBy(globalStore.search.searchDomain, isSearchEmpty));

  const ListView = carousel ? ScrollView : View;

  return races.length === 0 ? null : (
    <View style={{ marginBottom: spacing.large }}>
      <View
        style={{
          ...CONTAINER(deviceOrientation),
          ...CENTER_MIDDLE,
          justifyContent: "space-between",
        }}
      >
        <Text preset="heading">{title}</Text>

        <View style={{ ...CENTER_MIDDLE, gap: spacing.small }}>
          {displayMap ? (
            <MaterialCommunityIcons
              name="map"
              size={24}
              color={theme.colors.text}
              onPress={() => {
                navigation.navigate("map", { races });
              }}
            />
          ) : null}
          {displayFilters ? (
            <MaterialCommunityIcons
              name="tune-vertical"
              size={24}
              color={hasFiltersEmpty ? theme.colors.text : theme.colors.primary}
              onPress={() => {
                navigation.navigate("races");
              }}
            />
          ) : null}
          <Button
            style={{ ...SMALL_BUTTON, borderColor: theme.colors.primary }}
            textStyle={[SMALL_BUTTON_TEXT, { color: theme.colors.primary }]}
            preset="outlined"
            onPress={() =>
              navigation.navigate("races", displayFilters ? {} : { races })
            }
            RightAccessory={() => (
              <Feather
                name="chevron-right"
                size={15}
                color={theme.colors.primary}
                style={{ marginRight: -spacing.tiny }}
              />
            )}
          >
            {translate("runScreen.seeAll")}
          </Button>
        </View>
      </View>

      <ListView
        style={
          carousel
            ? { paddingBottom: spacing.large, marginBottom: -spacing.large }
            : {}
        }
        horizontal={true}
        showsHorizontalScrollIndicator={false}
      >
        {races
          .filter((_, i) => i < 5)
          .map((race, i) => {
            return (
              <View key={race.id}>
                {isReminder && i === 1 && <ReminderRace />}
                <RaceCalculator
                  isFavorite={isFavorites}
                  isTooltipVisible={canShowTooltip && i === 0}
                  race={race}
                  computeTime={computeTime}
                  onClose={
                    race.isLocal
                      ? () => {
                          alertMessage(
                            "editRaceScreen.removeTitle",
                            "editRaceScreen.removeDescription",
                          ).then(() => {
                            globalStore.removeLocalRace(race);
                          });
                        }
                      : undefined
                  }
                  style={
                    carousel
                      ? { width: layout.width / 1.3, alignSelf: "flex-start" }
                      : {}
                  }
                />
              </View>
            );
          })}
      </ListView>
    </View>
  );
});
