import { Race } from "../../models/race/race";
import { Text } from "../Text";
import { translate, TxKeyPath } from "swunitch-i18n";
import { formatNumber } from "../../utils/formatNumber";
import Slider from "@react-native-community/slider";
import * as Haptics from "expo-haptics";
import React from "react";
import { useBrandTheme } from "../../theme/use-brand-theme";
import { AntDesign } from "@expo/vector-icons";
import { View } from "react-native";
import { spacing } from "../../theme";
import { CENTER_MIDDLE } from "../../theme/view-style";

export function AbilitySlider({
  onValueChange,
  value,
  race,
  tx,
}: {
  onValueChange: (number: number) => void;
  value: number;
  race: Race;
  tx: TxKeyPath;
}) {
  const theme = useBrandTheme();
  const { colors } = theme;

  return (
    <>
      <View style={CENTER_MIDDLE}>
        <AntDesign
          name="reload1"
          size={15}
          onPress={() => onValueChange(1)}
          color={colors.text}
          style={{ marginRight: spacing.extraSmall, padding: spacing.micro }}
        />
        <Text preset="formLabel" size="xs">
          {translate(tx)}
          <Text size="xs">{formatNumber(value)}</Text>
        </Text>
      </View>
      <Text size="xxs">{translate("raceScreen.abilityNote")}</Text>

      <Slider
        style={{ height: 30, marginTop: -10 }}
        minimumValue={0.2}
        maximumValue={1.8}
        tapToSeek={true}
        step={0.01}
        value={value}
        onValueChange={(v) => {
          Haptics.impactAsync(Haptics.ImpactFeedbackStyle.Light);
          onValueChange(v);
        }}
        minimumTrackTintColor={
          race.backgroundColor
            ? race.backgroundColor + "77"
            : colors.primaryLight
        }
        maximumTrackTintColor={race.backgroundColor || colors.primaryDarker}
        thumbTintColor={race.backgroundColor || colors.primaryDarker}
      />
    </>
  );
}
